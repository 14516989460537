import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import {
  handleGAEventApplyCoupon,
  handleGAEventBackButton,
  handleGAEventCloseButton,
  handleGAEventReferralSuccess,
} from './eventsHelper'
import { ArrowLeft, Cross } from '@components/icons'
import { GENERAL_APPLY_TEXT } from '@components/utils/textVariables'
import { LoadingDots, useUI } from '@components/ui'
import axios from '@services/axiosInterceptorInstance'
import {
  NEXT_REFERRAL_REGISTER,
  NEXT_REFERRAL_VALIDATE_CODE,
  REFERRAL_ALREADY_APPLIED_CODE_MESSAGE,
  REFERRAL_EXISTING_CUSTOMER_MESSAGE,
} from '@components/utils/constants'
import { IDeviceInfo } from '@components/ui/context'

interface IProps {
  isReferralShow: boolean
  handleReferralShow: (val: boolean) => void
  deviceInfo: IDeviceInfo
  referralErrorMsg: string
  isAppLoading: boolean
  setAppliedBasketPromo: (val: any) => void
  handleSubmitInput: (val1: string, val2: any) => void
  handleCloseCoupon: (val: boolean) => void
  setCouponApplied: (val: boolean) => void
  setReferralErrorMsg: (val: string) => void
}

const ReferralInputPopup = (props: IProps) => {
  const {
    isReferralShow,
    handleReferralShow,
    deviceInfo,
    referralErrorMsg,
    isAppLoading,
    setAppliedBasketPromo,
    handleSubmitInput,
    handleCloseCoupon,
    setCouponApplied,
    setReferralErrorMsg,
  } = props
  const [referralLoader, setReferralLoader] = useState(false)
  const [referralCodeValue, setReferralCodeValue] = useState('')

  const { isOnlyMobile } = deviceInfo

  const { user } = useUI()

  const handleChangeReferral = (e: any) => {
    setReferralCodeValue(e.target.value)
  }

  const checkReferralCode = async () => {
    setReferralLoader(true)
    try {
      const {
        data: { result: referralValidateResponse },
      }: any = await axios.get(NEXT_REFERRAL_VALIDATE_CODE, {
        params: { referrerCode: referralCodeValue },
      })
      if (referralValidateResponse && referralValidateResponse.result) {
        const {
          data: { result: referralRegisterResponse },
        }: any = await axios.post(NEXT_REFERRAL_REGISTER, {
          referralId: referralValidateResponse.result?.id,
          emailOrPhoneNo: user?.telephone || user?.mobile || user?.username,
        })
        if (
          referralRegisterResponse &&
          referralRegisterResponse?.result &&
          referralRegisterResponse?.result?.voucherCode &&
          referralRegisterResponse?.result?.voucherCode !== ''
        ) {
          handleGAEventApplyCoupon({
            couponName: referralRegisterResponse?.result?.voucherCode ?? '',
            header: referralRegisterResponse?.result?.promoName ?? '',
            discountApplied: '',
            user,
          })
          const applyPromoResult: any = await handleSubmitInput(
            'apply',
            referralRegisterResponse?.result?.voucherCode
          )

          if (applyPromoResult) {
            const couponCode = referralRegisterResponse?.result?.voucherCode
            handleGAEventReferralSuccess({
              couponCode,
              user,
            })
            setAppliedBasketPromo({
              ...referralRegisterResponse.result,
              code: referralRegisterResponse.result?.voucherCode,
            })
            handleReferralShow(false)
            setTimeout(() => {
              handleCloseCoupon(false)
            }, 1000)
            setTimeout(() => {
              setCouponApplied(true)
            }, 2000)
            setReferralErrorMsg('')
          } else {
            setReferralErrorMsg(REFERRAL_ALREADY_APPLIED_CODE_MESSAGE)
          }
        } else if (
          referralRegisterResponse?.message === 'Already exist.' ||
          referralRegisterResponse?.result?.voucherCode === '' ||
          referralRegisterResponse?.result?.voucherCode === null
        ) {
          setReferralErrorMsg(REFERRAL_EXISTING_CUSTOMER_MESSAGE)
        } else {
          setReferralErrorMsg(referralRegisterResponse?.message)
        }
      } else {
        if (
          referralValidateResponse?.message?.toUpperCase() ===
          'INVALID REFERRER CODE.'
        ) {
          setReferralErrorMsg('Oops! Please check your code and try again.')
        } else {
          setReferralErrorMsg(referralValidateResponse?.message)
        }
      }
      setReferralLoader(false)
    } catch (error) {
      setReferralLoader(false)
      console.error(error)
    }
  }

  return (
    <Transition.Root show={isReferralShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-99"
        onClose={() => {
          handleGAEventCloseButton(user)
          handleReferralShow(false)
        }}
      >
        <div className="fixed inset-0 left-0 bg-black/[.5]" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`fixed ${
                isOnlyMobile ? 'top-auto bottom-0' : 'inset-y-0'
              } right-0 flex max-w-full pointer-events-none bottom-to-top`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom={`${
                  isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                }`}
                enterTo={`${isOnlyMobile ? 'translate-y-0' : 'translate-x-0'}`}
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom={`${
                  isOnlyMobile ? 'translate-y-0' : 'translate-x-0'
                }`}
                leaveTo={`${
                  isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                }`}
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto width-cart">
                  <div
                    className={`relative flex flex-col ${
                      isOnlyMobile ? 'h-auto' : 'h-full'
                    } overflow-y-auto bg-white shadow-xl z-99`}
                  >
                    <div className="sticky top-0 z-10 px-4 py-4 bg-white border-b border-gray-200 sm:px-6 left-1">
                      <div className="flex justify-between items-center">
                        <span className="flex justify-start gap-x-0 sm:gap-x-0 md:gap-x-2 items-center">
                          <button
                            type="button"
                            className="mr-2 text-black rounded-md outline-none hover:text-gray-500 hidden sm:hidden md:block "
                            onClick={() => {
                              handleGAEventBackButton(user)
                              handleReferralShow(false)
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <ArrowLeft
                              className="relative top-0 w-6 h-6"
                              aria-hidden="true"
                            />
                          </button>
                          <h3 className="font-bold text-20 xs-text-16 text-dark dark:text-black">
                            Enter Referral Code
                          </h3>
                        </span>
                        <button
                          type="button"
                          className="text-black rounded-md outline-none hover:text-gray-500 block sm:block md:hidden "
                          onClick={() => {
                            handleGAEventCloseButton(user)
                            handleReferralShow(false)
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <Cross
                            className="relative top-0 w-6 h-6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center px-4 py-4 my-2 w-full">
                      <div className="w-full">
                        <div className="w-full flex flex-col">
                          <div className="flex items-center justify-center w-full border border-gray-100 bg-dark-grey hover:border-orange-400">
                            <input
                              name={'referral-code'}
                              placeholder={'Referral Code'}
                              onChange={handleChangeReferral}
                              // value={value}
                              className="w-full min-w-0 px-4 py-4 text-gray-900 placeholder-gray-500 bg-transparent appearance-none focus:outline-none"
                            />
                          </div>
                          {referralErrorMsg ? (
                            <div className="mb-2 text-xs text-red-400">
                              {referralErrorMsg}
                            </div>
                          ) : null}
                          <button
                            onClick={checkReferralCode}
                            type="button"
                            title={GENERAL_APPLY_TEXT}
                            disabled={
                              !referralCodeValue?.length || isAppLoading
                            }
                            className={`w-full flex-1 my-4 p-4 flex items-center bg-orange justify-center font-medium text-white disabled:cursor-not-allowed`}
                          >
                            {referralLoader ? (
                              <span className="">
                                <LoadingDots />
                              </span>
                            ) : (
                              GENERAL_APPLY_TEXT
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ReferralInputPopup
