const GreenCouponIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.47239 0.512821L10.0123 0L10.5521 0.512821L12.6135 2.40093L15.4356 2.02797L16.1963 1.91142L16.319 2.63403L16.8098 5.31469L19.3374 6.57343L20 6.92308L19.681 7.55245L18.4294 10L19.681 12.4476L20 13.0769L19.3374 13.4266L16.8098 14.6853L16.319 17.366L16.1963 18.0886L15.4356 17.972L12.6135 17.5991L10.5521 19.4872L10.0123 20L9.47239 19.4872L7.41104 17.5991L4.58896 17.972L3.82822 18.0886L3.70552 17.366L3.21472 14.7086L0.687117 13.4266L0 13.0769L0.343558 12.4476L1.59509 10L0.343558 7.55245L0 6.92308L0.687117 6.57343L3.21472 5.29138L3.70552 2.63403L3.82822 1.91142L4.58896 2.02797L7.41104 2.40093L9.47239 0.512821ZM8.62336 13.2727C8.40059 13.2727 8.17782 13.1876 8.00814 13.0169L5.25486 10.2537C4.91505 9.91271 4.91505 9.35991 5.25486 9.01889C5.59423 8.67786 6.14593 8.67786 6.48531 9.01889L8.62336 11.1646L13.5147 6.25577C13.8541 5.91474 14.4058 5.91474 14.7451 6.25577C15.085 6.59679 15.085 7.14959 14.7451 7.49061L9.23858 13.0169C9.0689 13.1876 8.84613 13.2727 8.62336 13.2727Z"
        fill="#00890E"
      />
    </svg>
  )
}

export default GreenCouponIcon
