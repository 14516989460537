import { BASE_URL, AUTH_URL, CLIENT_ID, SHARED_SECRET, CLIENT_ID_STORE, SHARED_SECRET_STORE, } from './utils/constants'
import axios from 'axios'
import Agent from 'agentkeepalive'
import { BETTERCOMMERCE_COUNTRY, BETTERCOMMERCE_CURRENCY, BETTERCOMMERCE_DEFAULT_COUNTRY, BETTERCOMMERCE_DEFAULT_CURRENCY, BETTERCOMMERCE_DEFAULT_LANGUAGE, BETTERCOMMERCE_LANGUAGE, } from '@components/utils/constants'
import { ILogger, LOGGER_TYPE, storeLogs } from '@components/utils/logger'
import { tryParseJson } from './utils/parse-util'
import { decrypt } from './utils/cipher'

export enum PAGES {
  PDP = 'PDP',
  CART = 'Cart',
  PLP = 'PLP',
  HOME = 'HOME',
  REFERRAL = 'REFERRAL',
  OTHER_PAGE = 'OTHER_PAGE',
}

let keepAliveAgent, httpsKeepAliveAgent
const keepAliveAgentConfig: any = process.env.KEEP_ALIVE_AGENT_CONFIG ? tryParseJson(decrypt(process.env.KEEP_ALIVE_AGENT_CONFIG!)) : null

if (keepAliveAgentConfig) {
  // Create a reusable connection instance that can be passed around to different controllers
  keepAliveAgent = new Agent({
    maxSockets: keepAliveAgentConfig?.maxSockets,
    maxFreeSockets: keepAliveAgentConfig?.maxFreeSockets, // or 128 / os.cpus().length if running node across multiple CPUs
    timeout: keepAliveAgentConfig?.timeout, // active socket keepalive for 60 seconds
    freeSocketTimeout: keepAliveAgentConfig?.freeSocketTimeout, // free socket keepalive for 30 seconds
  })

  // HTTPS agent
  httpsKeepAliveAgent = new Agent.HttpsAgent({
    maxSockets: keepAliveAgentConfig?.maxSockets, // or 128 / os.cpus().length if running node across multiple CPUs
    maxFreeSockets: keepAliveAgentConfig?.maxFreeSockets, // or 128 / os.cpus().length if running node across multiple CPUs
    timeout: keepAliveAgentConfig?.timeout, // active socket keepalive for 30 seconds
    freeSocketTimeout: keepAliveAgentConfig?.freeSocketTimeout, // free socket keepalive for 30 seconds
  })
}

const SingletonFactory = (function () {
  let accessToken = ''
  let apiPath = ''
  let pageType = ''
  let machineId = ''
  let axiosInstance: any
  if (keepAliveAgentConfig) {
    axiosInstance = axios.create({
      // Create an agent for both HTTP and HTTPS
      httpAgent: keepAliveAgent,
      httpsAgent: httpsKeepAliveAgent,

      baseURL: BASE_URL,
      withCredentials: true,
    })
  } else {
    axiosInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    })
  }

  let isChannelStore = false

  const setStoreConfig = (val: boolean) => {
    isChannelStore = val
  }

  const getToken = () => accessToken
  const setToken = (token: string) => (accessToken = token)

  const setApiPath = (api: string) => (apiPath = api)

  const setPageType = (page: string) => (pageType = page)

  const setMachineId = (machineUniqueId: string) =>
    (machineId = machineUniqueId)

  axiosInstance.interceptors.request.use(
    (config: any) => {
      setStoreConfig(config?.isChannelStore)
      delete config?.isChannelStore

      config?.apiPath && setApiPath(config?.apiPath)
      delete config?.apiPath
      config?.pageType && setPageType(config?.pageType)
      delete config?.pageType
      config?.machineId && setMachineId(config?.machineId)
      delete config?.machineId

      const token = getToken()
      //this is to be changed when we implement currency / language switcher
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  function createAxiosResponseInterceptor() {
    const interceptor = axiosInstance.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        // Reject promise if usual error
        if (error?.response?.status !== 401) {
          return Promise.reject(error)
        }
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axiosInstance.interceptors.response.eject(interceptor)

        const url = new URL('oAuth/token', AUTH_URL)

        let client_ID = isChannelStore ? CLIENT_ID_STORE : CLIENT_ID
        let shared_secret_ID = isChannelStore
          ? SHARED_SECRET_STORE
          : SHARED_SECRET

        const fetcherStartTime = new Date().getTime()

        return axiosInstance({
          url: url.href,
          method: 'post',
          data: `client_id=${client_ID}&client_secret=${shared_secret_ID}&grant_type=client_credentials`,
        })
          .then(async (res: any) => {
            const fetcherEndTime = new Date().getTime()
            const log: ILogger = {
              machineId,
              fetcherResponseTime: fetcherEndTime - fetcherStartTime,
              fetcherStartTime,
              fetcherEndTime,
              functionType: 'fetcher -> user Auth test',
              message: 'Success',
              apiPath,
              type: LOGGER_TYPE.SUCCESS,

              pageType,
            }
            await storeLogs(log)

            setToken(res.data.access_token)
            error.response.config.headers['Authorization'] =
              'Bearer ' + res.data.access_token
            return axiosInstance(error.response.config)
          })
          .catch(async (error: any) => {
            //@TODO redirect here to Login page
            const fetcherEndTime = new Date().getTime()
            const log: ILogger = {
              machineId,
              fetcherResponseTime: fetcherEndTime - fetcherStartTime,
              fetcherStartTime,
              fetcherEndTime,
              failedOn: 'fetcher -> user Auth',
              functionType: 'fetcher -> user Auth',
              message: 'Failed',
              apiPath,
              type: LOGGER_TYPE.ERROR,
              error: error?.response?.data?.message
                ? JSON.stringify(error)
                : 'unknown error',
              pageType,
            }

            if (
              error?.response?.data?.Error?.toLowerCase() ===
                'user token is invalid' ||
              error?.response?.data?.Error?.toLowerCase() ===
                'invalid user token' ||
              error?.response?.data?.Error?.toLowerCase() ===
                'invalied user token' ||
              error?.response?.data?.Error?.toLowerCase() ===
                'user token expired' ||
              error?.response?.data?.message?.toLowerCase() ===
                'user token is invalid' ||
              error?.response?.data?.message?.toLowerCase() ===
                'invalid user token' ||
              error?.response?.data?.message?.toLowerCase() ===
                'invalied user token' ||
              error?.response?.data?.message?.toLowerCase() ===
                'user token expired'
            ) {
              log.error = error?.response?.data?.message?.toLowerCase()
              await storeLogs(log)
              return Promise.reject({
                // ...error,
                tokenMessage: {
                  ...error?.response?.data,
                  Error: 'User Token is Invalid',
                },
              })
            }
            await storeLogs(log)
            return Promise.reject(error)
          })
          .finally(createAxiosResponseInterceptor)
      }
    )
  }

  createAxiosResponseInterceptor()
  return { axiosInstance }
})()

const axiosInstance = SingletonFactory.axiosInstance

Object.freeze(axiosInstance)

const getCurrentPage = ({
  apiPath,
  slug,
  isCart,
  isPDP,
  currentPage,
}: {
  apiPath: string
  slug?: string
  isPDP?: boolean
  isCart?: boolean
  currentPage?: string
}) => {
  if (apiPath?.includes('slug')) {
    if (isPDP) {
      return PAGES.PDP
    } else {
      return PAGES.OTHER_PAGE
    }
  } else if (apiPath?.includes('minimal')) {
    return PAGES.PLP
  } else if (apiPath?.includes('page')) {
    if (slug && slug === 'home') {
      return PAGES.HOME
    } else if (slug && slug === 'referral') {
      return PAGES.REFERRAL
    } else {
      return PAGES.OTHER_PAGE
    }
  } else if (isPDP) {
    return PAGES.PDP
  } else if (isCart) {
    return PAGES.CART
  } else if (currentPage) {
    return currentPage
  } else {
    return PAGES.OTHER_PAGE
  }
}

const fetcher = async ({
  url = '',
  method = 'post',
  data = {},
  params = {},
  headers = {},
  cookies = {},
  baseUrl = '',
  isChannelStore = false,
  isPDP,
  isCart,
  currentPage,
  machineId = '',
}: // userEncryptDetails,
any): Promise<any> => {
  const apiPath = url.match(/.*\/([^,?]+)/)?.[1] ?? ''
  const pageType = getCurrentPage({
    apiPath,
    slug: params?.slug,
    isCart,
    isPDP,
    currentPage,
  })
  const computedUrl = new URL(url, baseUrl || BASE_URL)
  const newConfig = {
    Currency:
      cookies.Currency ||
      BETTERCOMMERCE_CURRENCY ||
      BETTERCOMMERCE_DEFAULT_CURRENCY,
    Language:
      cookies.Language ||
      BETTERCOMMERCE_LANGUAGE ||
      BETTERCOMMERCE_DEFAULT_LANGUAGE,
    Country:
      cookies.Country ||
      BETTERCOMMERCE_COUNTRY ||
      BETTERCOMMERCE_DEFAULT_COUNTRY,
    DeviceId: cookies?.deviceId || '',
    SessionId: cookies?.sessionId || '',
  }

  const tempEncryptedDetails = headers.authToken
    ? { UserToken: headers.authToken }
    : cookies.userEncryptedDetails
    ? { UserToken: cookies.userEncryptedDetails }
    : {}
  const tempUserId = headers.userId
    ? { UserId: headers.userId }
    : cookies.userId
    ? { UserId: cookies.userId }
    : {}
  delete headers.authToken
  delete headers.userId
  const config: any = {
    method: method,
    url: computedUrl.href,
    headers: {
      ...headers,
      ...newConfig,
      ...tempEncryptedDetails,
      ...tempUserId,
    },
    isChannelStore,
    pageType,
    apiPath,
    machineId,
  }

  if (Object.keys(params).length) {
    config.params = params
  }

  if (Object.keys(data).length) {
    config.data = data
  }

  const fetcherStartTime = new Date().getTime()

  try {
    //console.log('**** config.url', config.url, config?.headers, config.data)
    const response: any = await axiosInstance(config)
    const fetcherEndTime = new Date().getTime()
    // console.log('**** config.url', config.url, 'response')
    return {
      ...response.data,
      newRelicData: {
        fetcherStartTime,
        fetcherEndTime,
      },
    }
  } catch (error: any) {
    //console.log('**** config.url error', config.url, error)
    console.error(':: error inside fetcher', error)

    const fetcherEndTime = new Date().getTime()
    const log: ILogger = {
      machineId,
      fetcherResponseTime: fetcherEndTime - fetcherStartTime,
      fetcherStartTime,
      fetcherEndTime,
      failedOn: 'fetcher',
      message: 'Failed',
      apiPath: url,
      type: LOGGER_TYPE.ERROR,
      error: error?.response?.data?.message
        ? JSON.stringify(error)
        : 'unknown error',
      pageType,
    }
    await storeLogs(log)
    if (error?.tokenMessage?.Error) {
      throw new Error(error?.tokenMessage?.Error)
    }
    if (error?.response?.data?.message) {
      throw new Error(error?.response?.data?.message)
    }
  }
}
export default fetcher
