import { DeviceType } from '@commerce/utils/use-device'
import { Messages } from '@components/utils/constants'
import {
  JusPay,
  LocalStorage,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import {
  errorSubmitData,
  getOrderId,
  getOrderInfo,
  getUPIAppBundleId,
  getUPIAppPackageName,
  isUpiPSP,
  replaceAll,
  resetSubmitData,
  upiPSP,
  upiType,
} from '@framework/utils/app-util'
import {
  GOKWIK_COD_METHOD_SYSTEM_NAME,
  JUSPAY_OFFERS_APPLY_COUPON_ENABLED,
  USE_MWEB_INTENT_FOR_MOBILE_WALLETS,
} from '@framework/utils/constants'
import {
  collectUPI,
  createUPIIntentTransaction,
  createWalletTransaction,
} from '@framework/utils/juspay-util'
import { matchStrings } from '@framework/utils/parse-util'
import Router from 'next/router'
import { useUI } from '@components/ui'
import { IDeviceInfo } from '@components/ui/context'
import {
  CODHandler,
  fetchPaymentMethod,
  getAdditionalCharges,
  getUPIIntentInput,
  handleWalletUPIIntentResult,
  upiTransactionHandler,
} from '../helper'
import {
  IOffersInput,
  IS_PAYMENT_REDIRECT_ENABLED,
} from '@components/checkout/helper'
import Cookies from 'js-cookie'

interface IProps {
  isChannelStore: boolean
  showLoader: () => void
  deviceInfo: IDeviceInfo
  handleFetchOffers: any
  handleConvertOrder: any
  initiateJusPayOrder: any
  applicableCoupon: any
  hideLoader: any
  dispatch: any
  defaultCheckoutPayment: any
  confirmOrder: any
  getUserId: () => void
  storeId: string | string[] | null
  defaultShippingAddress: any
  isGoKwikUser: boolean
  trackMoEngageEvent: any
  shippingCountry: any
}

const useBasketExpressCheckoutTransactions = ({
  isChannelStore,
  showLoader,
  deviceInfo,
  handleFetchOffers,
  handleConvertOrder,
  initiateJusPayOrder,
  applicableCoupon,
  hideLoader,
  dispatch,
  defaultCheckoutPayment,
  confirmOrder,
  getUserId,
  storeId,
  defaultShippingAddress,
  isGoKwikUser,
  trackMoEngageEvent,
  shippingCountry,
}: IProps) => {
  const { isMobile, isIPadorTablet, deviceType } = deviceInfo
  const {
    user,
    cartItems,
    setCartItems,
    setBasketId,
    setAlert,
    setOrderId,
    addressesList,
    setSidebarView,
    closeSidebarIfPresent,
  } = useUI()

  const upiPayTransaction = async (preferredPaymentMethod: any) => {
    showLoader()
    const upiKey = preferredPaymentMethod?.key
    if (upiKey) {
      if (isUpiPSP(upiKey)) {
        // For Upi Apps
        if (isMobile || isIPadorTablet) {
          if (JUSPAY_OFFERS_APPLY_COUPON_ENABLED) {
            const params: IOffersInput = {
              methodType: PaymentMethodType.UPI,
              methodRef: getUPIAppBundleId(
                preferredPaymentMethod?.method ?? ''
              ),
              extras: {
                upi_app: getUPIAppBundleId(
                  preferredPaymentMethod?.method ?? ''
                ),
                payment_method: PaymentMethodType.UPI,
                txn_type: 'UPI_PAY',
              },
            }
            const offersResult = await handleFetchOffers(params)
          }

          showLoader()
          const success = await handleConvertOrder(PaymentMethodType.UPI) // Moved here from component load

          if (success) {
            const jusPayOrderResult: any = await initiateJusPayOrder(
              PaymentMethodType.UPI,
              false,
              `${upiKey}_${upiKey}`
            )
            if (jusPayOrderResult?.orderId) {
              const orderInfo = await getOrderInfo()
              if (isMobile || isIPadorTablet) {
                upiTransactionHandler({
                  upiKey,
                  orderInfo,
                  applicableCoupon,
                  hideLoader,
                  dispatch,
                  deviceType,
                })
              }
            } else {
              hideLoader()
              resetSubmitData(dispatch)
              errorSubmitData(dispatch, { error_code: 'GENERIC_ERROR' })
            }
          } else {
            hideLoader()
            resetSubmitData(dispatch)
          }
        } else {
          walletPayTransaction(preferredPaymentMethod)
        }
      } else {
        // For VPA
        if (JUSPAY_OFFERS_APPLY_COUPON_ENABLED) {
          const params: IOffersInput = {
            methodType: PaymentMethodType.UPI,
            methodRef: upiKey,
            extras: {
              upi_vpa: upiKey,
              payment_method: PaymentMethodType.UPI,
              txn_type: 'UPI_COLLECT',
            },
          }
          const offersResult = await handleFetchOffers(params)
        }

        showLoader()
        const success = await handleConvertOrder(PaymentMethodType.UPI) // Moved here from component load

        if (success) {
          const jusPayOrderResult: any = await initiateJusPayOrder(
            PaymentMethodType.UPI,
            false,
            upiType(upiKey)
          )
          if (jusPayOrderResult?.orderId) {
            const orderInfo = await getOrderInfo()
            let data = {
              order_id: jusPayOrderResult?.orderId, //orderInfo?.jusPayOrder?.orderId,
              payment_method_type: JusPay.UPI.PAYMENT_METHOD_TYPE,
              payment_method: JusPay.UPI.PAYMENT_METHOD,
              txn_type: JusPay.UPI.TRANSACTION_TYPE,
              upi_vpa: upiKey,
              format: 'json',
              redirect_after_payment: true,
            }

            if (
              applicableCoupon?.offers?.length &&
              applicableCoupon?.offers[0]?.offer_id
            ) {
              data = {
                ...data,
                ...{
                  offers: JSON.stringify([
                    applicableCoupon?.offers[0]?.offer_id,
                  ]),
                },
              }
            }
            const authToken =
              localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
            const user = localStorage?.getItem('user')
            const userDetails = user ? JSON.parse(user) : {}
            const userId = userDetails?.userId
            const collectUPIResult = await collectUPI(data, authToken, userId)

            if (collectUPIResult?.hasError) {
              hideLoader()
              resetSubmitData(dispatch)
              errorSubmitData(dispatch, collectUPIResult?.error)
            } else {
              if (IS_PAYMENT_REDIRECT_ENABLED) {
                if (
                  (isMobile || isIPadorTablet) &&
                  orderInfo?.jusPayOrder?.deepLink
                ) {
                  if (deviceType === DeviceType.IOS) {
                    let deepLink = orderInfo?.jusPayOrder?.deepLink
                    const upiPsp = upiPSP(upiKey)
                    if (upiPsp?.webPrefixUrl) {
                      deepLink = replaceAll(
                        deepLink,
                        upiPsp?.webPrefixUrl,
                        Messages.Validations.RegularExpressions
                          .REPLACE_DEFAULT_UPI_WEB_PREFIX_URL
                      )
                    }
                    Router.replace(deepLink).then(() => {
                      setTimeout(() => {
                        if (collectUPIResult?.payment?.authentication?.url) {
                          Router.replace(
                            collectUPIResult?.payment?.authentication?.url
                          )
                          resetSubmitData(dispatch)
                        }
                      }, 2000)
                    })
                  } else {
                    Router.replace(orderInfo?.jusPayOrder?.deepLink)
                    setTimeout(() => {
                      if (collectUPIResult?.payment?.authentication?.url) {
                        Router.replace(
                          collectUPIResult?.payment?.authentication?.url
                        )
                        resetSubmitData(dispatch)
                      }
                    }, 2000)
                  }
                } else {
                  if (collectUPIResult?.payment?.authentication?.url) {
                    Router.push(collectUPIResult?.payment?.authentication?.url)
                    resetSubmitData(dispatch)
                  }
                }
              }
            }
          } else {
            hideLoader()
            resetSubmitData(dispatch)
            errorSubmitData(dispatch, { error_code: 'GENERIC_ERROR' })
          }
        } else {
          hideLoader()
          resetSubmitData(dispatch)
        }
      }
    } else {
      defaultCheckoutPayment({
        defaultShippingAddress,
        isChannelStore,
        storeId,
      })
      hideLoader()
    }
  }

  const walletPayTransaction = async (preferredPaymentMethod: any) => {
    showLoader()
    if (preferredPaymentMethod?.key) {
      if (JUSPAY_OFFERS_APPLY_COUPON_ENABLED) {
        const params: IOffersInput = {
          methodType: PaymentMethodType.WALLET,
          methodRef: preferredPaymentMethod?.key,
          extras: {
            payment_method: preferredPaymentMethod?.key,
          },
        }
        const offersResult = await handleFetchOffers(params)
      }

      showLoader()
      const success = await handleConvertOrder(PaymentMethodType.WALLET) // Moved here from component load

      if (success) {
        const jusPayOrderResult: any = await initiateJusPayOrder(
          PaymentMethodType.WALLET,
          false,
          `${preferredPaymentMethod?.method}_${preferredPaymentMethod?.type}`
        )
        if (jusPayOrderResult?.orderId) {
          const orderInfo = await getOrderInfo()
          if (
            (isMobile || isIPadorTablet) &&
            USE_MWEB_INTENT_FOR_MOBILE_WALLETS
          ) {
            const packageName = getUPIAppPackageName(
              preferredPaymentMethod?.type
            )
            if (packageName) {
              let upiIntentInput = getUPIIntentInput(orderInfo, packageName)
              if (
                applicableCoupon?.offers?.length &&
                applicableCoupon?.offers[0]?.offer_id
              ) {
                upiIntentInput = {
                  ...upiIntentInput,
                  ...{
                    offers: JSON.stringify([
                      applicableCoupon?.offers[0]?.offer_id,
                    ]),
                  },
                }
              }
              const authToken =
                localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ??
                ''
              const user = localStorage?.getItem('user')
              const userDetails = user ? JSON.parse(user) : {}
              const userId = userDetails?.userId
              const upiIntentResult = await createUPIIntentTransaction(
                upiIntentInput,
                authToken,
                userId
              )
              if (upiIntentResult?.hasError) {
                hideLoader()
                resetSubmitData(dispatch)
                errorSubmitData(dispatch, upiIntentResult?.error, true)
              } else {
                if (IS_PAYMENT_REDIRECT_ENABLED) {
                  if (upiIntentResult?.upiIntentUrls?.length) {
                    handleWalletUPIIntentResult(
                      upiIntentResult,
                      preferredPaymentMethod,
                      dispatch
                    )
                  } else {
                    if (upiIntentResult?.upiIntentUrl) {
                      Router.replace(upiIntentResult?.upiIntentUrl)

                      setTimeout(() => {
                        if (upiIntentResult?.payment?.authentication?.url) {
                          Router.replace(
                            upiIntentResult?.payment?.authentication?.url
                          )
                          resetSubmitData(dispatch)
                        }
                      }, 2000)
                    }
                  }
                }
              }
            } else {
              hideLoader()
              resetSubmitData(dispatch)
              errorSubmitData(dispatch, { error_code: 'UNSUPPORTED_UPI_APP' })
            }
          } else {
            let walletInput = {
              order_id: getOrderId(orderInfo?.order),
              payment_method_type: PaymentMethodType.WALLET,
              payment_method: preferredPaymentMethod?.key,
              redirect_after_payment: true,
              format: 'json',
            }
            if (
              applicableCoupon?.offers?.length &&
              applicableCoupon?.offers[0]?.offer_id
            ) {
              walletInput = {
                ...walletInput,
                ...{
                  offers: JSON.stringify([
                    applicableCoupon?.offers[0]?.offer_id,
                  ]),
                },
              }
            }
            const authToken =
              localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
            const user = localStorage?.getItem('user')
            const userDetails = user ? JSON.parse(user) : {}
            const userId = userDetails?.userId
            const walletResult = await createWalletTransaction(
              walletInput,
              authToken,
              userId
            )

            if (walletResult?.hasError) {
              hideLoader()
              resetSubmitData(dispatch)
              errorSubmitData(dispatch, walletResult?.error, true)
            } else {
              if (IS_PAYMENT_REDIRECT_ENABLED && walletResult?.url) {
                resetSubmitData(dispatch)
                Router.push(walletResult?.url)
              }
            }
          }
        } else {
          hideLoader()
          resetSubmitData(dispatch)
          errorSubmitData(dispatch, { error_code: 'GENERIC_ERROR' })
        }
      } else {
        hideLoader()
        resetSubmitData(dispatch)
      }
    } else {
      hideLoader()
    }
  }

  const payOnDeliveryTransaction = async (preferredPaymentMethod: any) => {
    const shippingMethod = cartItems?.shippingMethods?.length
      ? cartItems?.shippingMethods[0]
      : null

    const handleAsync = async (method: any) => {
      const additionalServiceCharge: any = getAdditionalCharges(method)

      localStorage.removeItem(LocalStorage.Key.CONVERTED_ORDER)
      localStorage.removeItem(LocalStorage.Key.ORDER_RESPONSE)
      localStorage.removeItem(LocalStorage.Key.ORDER_PAYMENT)
      const msgCode = await confirmOrder(method, defaultShippingAddress, shippingMethod, PaymentMethodType.COD, additionalServiceCharge, true)
      if (!msgCode) {
        try {
          const orderInfo = await getOrderInfo()
          await CODHandler(orderInfo?.orderResponse?.result?.id, method, {
            isChannelStore,
            setBasketId,
            getUserId,
            setCartItems,
            setOrderId,
            storeId,
            cartItems,
            user,
            isGoKwikUser,
            addressesList,
            defaultShippingAddress,
            dispatch,
            trackMoEngageEvent
          })
        } catch (e) {
          resetSubmitData(dispatch)
          errorSubmitData(dispatch, { error_code: 'GENERIC_ERROR' })
        }
      } else {
        if (Messages.Errors[msgCode]) {
          setAlert({ type: 'error', msg: Messages.Errors[msgCode] })
        } else {
          setAlert({ type: 'error', msg: msgCode })
        }
        resetSubmitData(dispatch)
      }
    }
    showLoader()
    const paymentMethodsResult: any = await fetchPaymentMethod(
      cartItems,
      shippingCountry
    )
    if (paymentMethodsResult?.data && paymentMethodsResult?.data?.length) {
      const method = paymentMethodsResult?.data?.find((x: any) =>
        matchStrings(x.systemName, GOKWIK_COD_METHOD_SYSTEM_NAME || ' ', true)
      )
      if (method) {
        await handleAsync(method)
        setSidebarView('')
        closeSidebarIfPresent()
        hideLoader()
      }
    }
  }

  return {
    upiPayTransaction,
    walletPayTransaction,
    payOnDeliveryTransaction,
  }
}

export { useBasketExpressCheckoutTransactions }
