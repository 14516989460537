import { gql } from '@apollo/client'
import { IPromotion } from '@components/men/helpers/pomotions.model'
import { graphQLRequestWithApollo } from '@services/request.service'

export interface FilterPayload {
  IsSelected: boolean
  Key: string
  Value: string
  name: string
}

export interface IProductsPayload {
  filters?: FilterPayload[]
  sortBy?: string
  sortOrder?: string
  currentPage?: number
  collectionId?: string
  categoryId?: string
  slug?: string
}

export interface IGetProductPayload {
  slug: string
  isPDP?: boolean
  postCode?: string
  isChannelStore?: boolean
}

export interface IGetBasketPromotionResponse {
  applicablePromotions: IPromotion[]
  availablePromotions: IPromotion[]
}

const productsQuery = gql`
  query products(
    $collectionId: String
    $sortBy: String
    $sortOrder: String
    $currentPage: Int
    $categoryId: String
    $slug: String
    $filters: [FilterPayload]
  ) {
    products(
      query: {
        collectionId: $collectionId
        sortBy: $sortBy
        sortOrder: $sortOrder
        currentPage: $currentPage
        categoryId: $categoryId
        slug: $slug
        filters: $filters
      }
    ) {
      products {
        total
        currentPage
        pages
        groups
        sortList {
          key
          value
        }
        filters {
          displayOrder
          key
          name
          value
          from
          to
          items {
            count
            displayOrder
            name
            nameKeys
          }
        }
        results {
          name
          brand
          sku
          stockCode
          slug
          image
          secondImage
          variantGroupCode
          recordId
          rating
          currentStock
          classification {
            category
            mainCategoryName
          }
          price {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          preOrder {
            isEnabled
            launchDateTime
          }
          attributes {
            compareAtPLP
            display
            key
            value
          }
        }
      }
      plpPageData {
        metaTitle
        metaDescription
        metaKeywords
        customInfo3
        customInfo2
        customInfo1
        name
        description
        allowFacets
        customLinks {
          label
          link
          displayOrder
        }
        id
        images {
          link
          mobileUrl
          url
          title
          description
          gif
          video
        }
      }
    }
  }
`

const getProductQuery = gql`
  query getProduct(
    $slug: String!
    $isPDP: Boolean
    $postCode: String
    $isChannelStore: Boolean
  ) {
    getProduct(
      query: {
        slug: $slug
        isPDP: $isPDP
        postCode: $postCode
        isChannelStore: $isChannelStore
      }
    ) {
      product {
        id
        recordId
        productCode
        stockCode
        barCode
        name
        fullName
        seoName
        metaTitle
        metaDescription
        metaKeywords
        canonicalTags
        link
        shortDescription
        description
        notes
        brand
        brandLogo
        brandId
        brandRecordId
        subBrand
        subBrandId
        attributeSet
        uom
        uomValue
        availability
        seoAvailability
        deliveryMessage
        stockAvailabilityMessage
        fulfilFromWarehouse
        fulfilFromStore
        fulfilFromSupplier
        fulfilFromWarehouseDays
        fulfilFromSupplierDays
        fulfilFromInstoreDays
        currentStock
        rating
        reviewCount
        displayTemplate
        isDiscontinued
        isVisible
        isGiftWrapApplied
        giftWrapConfig
        couponProvider
        subscriptionEnabled
        subscriptionPlanType
        variantGroupCode
        soldIndependently
        componentsInStock
        displayInBasket
        launchDate
        cutOffTime
        supplierCutoffTime
        deliveryETA
        image
        images {
          name
          image
          tag
        }
        videos {
          productId
          recordId
          url
          isDefault
          displayOrder
        }
        pdFs {
          productId
          recordId
          url
          name
          displayOrder
        }
        dimensions {
          weight
          height
          length
          width
        }
        preOrder {
          isEnabled
          launchDateTime
        }
        flags {
          sellWithoutInventory
          isFreeDelivery
        }
        collections {
          collectionId
          name
        }
        promotions {
          promoCode
          name
          badgeDisplay
          badgeLabel
          badgePriority
          badgeCSS
        }
        mappedCategories {
          categoryId
          categoryName
          parentCategoryId
          parentCategoryName
        }
        classification {
          category
          mainCategoryName
          categoryId
          categoryCode
          mainCategoryId
          itemTypeCode
          itemType
          attributeSet
          attributeSetId
        }
        listPrice {
          maxPrice
          minPrice
          raw {
            tax
            withTax
            withoutTax
          }
          formatted {
            tax
            withTax
            withoutTax
          }
        }
        price {
          maxPrice
          minPrice
          raw {
            tax
            withTax
            withoutTax
          }
          formatted {
            tax
            withTax
            withoutTax
          }
        }
        customAttributes {
          key
          customkey
          display
          value
          fieldText
          valueText
        }
        variantAttributes {
          fieldName
          fieldCode
          fieldLabel
          fieldValues {
            fieldValue
            fieldLabel
            fieldGroupName
            displayOrder
            slug
          }
          displayInProductDetail
          displayInProductWidget
        }
        variantProducts {
          stockCode
          productId
          slug
          image
          productName
          listPrice {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          price {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          currentStock
          variantGroupCode
          attributes {
            fieldCode
            fieldName
            fieldValue
            fieldLabel
          }
        }
        reviews {
          title
          rating
          comment
          postedBy
          postedOn
          nickName
          sections {
            id
            sectionName
            options {
              id
              name
              isChecked
              parentItemValue
            }
          }
        }
        relatedProducts {
          productId
          name
          stockCode
          relatedTypeCode
          relationDirectionCode
          images {
            name
            tag
            image
            imageTypes
            url
            mobileUrl
            alt
            link
            description
            subTitle
            linkType
            buttonText
            eventCategory
            eventAction
            gaValue
            isActive
            cssClass
            title
            displayOrder
            imageTags {
              recordId
              src
              text
              name
              link
              shapes {
                type
                units
                geometry {
                  x
                  y
                  width
                  height
                }
              }
              productType
            }
          }
          shortDescription
          description
          image
          listPrice {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          price {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          slug
          currentStock
          brand
          subBrand
          hasVariant
          itemType
          sku
        }
        freeProducts {
          id
          recordId
          name
          slug
          brand
          subBrand
          shortDescription
          description
          stockCode
          images {
            name
            tag
            image
            imageTypes
            url
            mobileUrl
            alt
            link
            description
            subTitle
            linkType
            buttonText
            eventCategory
            eventAction
            gaValue
            isActive
            cssClass
            title
            displayOrder
            imageTags {
              recordId
              src
              text
              name
              link
              shapes {
                type
                units
                geometry {
                  x
                  y
                  width
                  height
                }
              }
              productType
            }
          }
          image
          currentStock
        }
      }
      snippets {
        name
        type
        placement
        content
        excludedUrl
        microsites
      }
    }
  }
`

const getProductRestDataQuery = gql`
  query getProductRestData($recordId: String!) {
    getProductRestData(recordId: $recordId) {
      recordId
      relatedProductData {
        relatedProducts {
          bestSeller
          brand
          brandSlug
          image
          currentStock
          cutOffTime
          deliveryETA
          deliveryMessage
          description
          displayOrder
          extendedDescription
          exclusive
          fulfilFromInstoreDays
          fulfilFromStore
          fulfilFromSupplier
          fulfilFromSupplierDays
          fulfilFromWarehouseDays
          groupName
          hasVariant
          isActive
          isMasterStock
          isVisible
          itemType
          name
          newLaunch
          onSale
          rating
          recordId
          relatedType
          reviewCount
          seoName
          shortDescription
          sku
          slug
          stockAvailabilityMessage
          stockCode
          subBrand
          subscriptionPlanType
          supplierCutoffTime
          trending
          variantGroupCode
          variantProductsAttributeMinimal {
            fieldCode
            fieldName
            fieldValues {
              displayOrder
              fieldGroupName
              fieldLabel
              fieldValue
              slug
            }
          }
          variantProductsMinimal {
            image
            price {
              maxPrice
              minPrice
              raw {
                tax
                withTax
                withoutTax
              }
              formatted {
                tax
                withTax
                withoutTax
              }
            }
            productId
            productName
            slug
            stockCode
            variantAttributes {
              displayOrder
              fieldValue
              fieldLabel
              fieldGroupName
              slug
            }
          }
          promotions {
            badgeCSS
            badgeLabel
            badgeDisplay
            badgePriority
            name
            promoCode
          }
          preOrder {
            isEnabled
            launchDateTime
          }
          priceFrom {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          listPrice {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          price {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          images {
            alt
            buttonText
            cssClass
            description
            displayOrder
            eventAction
            eventCategory
            gaValue
            image
            imageTags {
              link
              name
              productType
              recordId
              shapes {
                type
                units
                geometry {
                  height
                  width
                  x
                  y
                }
              }
              src
              text
            }
            imageTypes
            isActive
            link
            linkType
            mobileUrl
            name
            subTitle
            tag
            title
            url
          }
          flags {
            isFreeDelivery
            sellWithoutInventory
          }
          groupNameList {
            name
            relatedTypeCode
          }
          classification {
            attributeSet
            attributeSetId
            category
            categoryId
            itemType
            itemTypeCode
            mainCategoryId
            mainCategoryName
          }
          attributes {
            compareAtPDP
            compareAtPLP
            customkey
            display
            displayInProductList
            displayInProductDetail
            fieldText
            groupDisplayOrder
            isVariantAttribute
            key
            originalValue
            value
            valueText
          }
          seoName
        }
      }
      productPromos {
        promotions {
          availablePromotions {
            additionalInfo1
            additionalInfo2
            additionalInfo3
            additionalInfo4
            additionalInfo5
            additionalInfo6
            additionalInfo7
            additionalInfo8
            additionalInfo9
            additionalInfo10
            code
            croMessage
            croSuccessMessage
            fromDate
            isCROEnabled
            name
            promotionId
            promoType
            toDate
            useWithOtherDiscountCode
          }
          bestAvailablePromotion {
            additionalInfo1
            additionalInfo2
            additionalInfo3
            additionalInfo4
            additionalInfo5
            additionalInfo6
            additionalInfo7
            additionalInfo8
            additionalInfo9
            additionalInfo10
            code
            croMessage
            croSuccessMessage
            fromDate
            isCROEnabled
            name
            promotionId
            promoType
            toDate
            useWithOtherDiscountCode
          }
        }
      }
      productRatings {
        productRating {
          currentPage
          pageSize
          totalRecord
          ratingAverage
          productReviews {
            comment
            nickName
            postedBy
            postedOn
            rating
            reviewSections
            title
            totalRecord
            images {
              data
              name
            }
            sections {
              id
              sectionName
              options {
                id
                isChecked
                name
                parentItemValue
              }
            }
          }
        }
      }
    }
  }
`

const getQuickViewProductQuery = gql`
  query getProduct(
    $slug: String!
    $isPDP: Boolean
    $postCode: String
    $isChannelStore: Boolean
  ) {
    getProduct(
      query: {
        slug: $slug
        isPDP: $isPDP
        postCode: $postCode
        isChannelStore: $isChannelStore
      }
    ) {
      product {
        id
        videos {
          productId
          recordId
          url
          isDefault
          displayOrder
        }
        recordId
        stockCode
        isVisible
        name
        brand
        availability
        currentStock
        rating
        isGiftWrapApplied
        giftWrapConfig
        subscriptionEnabled
        variantGroupCode
        image
        link
        productCode
        images {
          name
          image
          tag
        }
        preOrder {
          isEnabled
          launchDateTime
        }
        flags {
          sellWithoutInventory
          isFreeDelivery
        }
        mappedCategories {
          categoryId
          categoryName
          parentCategoryId
          parentCategoryName
        }
        classification {
          category
          mainCategoryName
          categoryId
          categoryCode
          mainCategoryId
          itemTypeCode
          itemType
          attributeSet
          attributeSetId
        }
        listPrice {
          maxPrice
          minPrice
          raw {
            tax
            withTax
            withoutTax
          }
          formatted {
            tax
            withTax
            withoutTax
          }
        }
        price {
          maxPrice
          minPrice
          raw {
            tax
            withTax
            withoutTax
          }
          formatted {
            tax
            withTax
            withoutTax
          }
        }
        customAttributes {
          key
          customkey
          display
          value
          fieldText
          valueText
        }
        variantProducts {
          stockCode
          productId
          slug
          image
          productName
          listPrice {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          price {
            maxPrice
            minPrice
            raw {
              tax
              withTax
              withoutTax
            }
            formatted {
              tax
              withTax
              withoutTax
            }
          }
          currentStock
          variantGroupCode
          attributes {
            fieldCode
            fieldName
            fieldValue
            fieldLabel
          }
        }
        variantAttributes {
          fieldName
          fieldCode
          fieldLabel
          fieldValues {
            fieldValue
            fieldLabel
            fieldGroupName
            displayOrder
            slug
          }
          displayInProductDetail
          displayInProductWidget
        }
        relatedProducts {
          stockCode
          # productId
          # name
          # relatedTypeCode
          # relationDirectionCode
          # images {
          #   name
          #   tag
          #   image
          #   imageTypes
          #   url
          #   mobileUrl
          #   alt
          #   link
          #   description
          #   subTitle
          #   linkType
          #   buttonText
          #   eventCategory
          #   eventAction
          #   gaValue
          #   isActive
          #   cssClass
          #   title
          #   displayOrder
          #   imageTags {
          #     recordId
          #     src
          #     text
          #     name
          #     link
          #     shapes {
          #       type
          #       units
          #       geometry {
          #         x
          #         y
          #         width
          #         height
          #       }
          #     }
          #     productType
          #   }
          # }
          # shortDescription
          # description
          # image
          # listPrice {
          #   maxPrice
          #   minPrice
          #   raw {
          #     tax
          #     withTax
          #     withoutTax
          #   }
          #   formatted {
          #     tax
          #     withTax
          #     withoutTax
          #   }
          # }
          # price {
          #   maxPrice
          #   minPrice
          #   raw {
          #     tax
          #     withTax
          #     withoutTax
          #   }
          #   formatted {
          #     tax
          #     withTax
          #     withoutTax
          #   }
          # }
          # slug
          # currentStock
          # brand
          # subBrand
          # hasVariant
          # itemType
          # sku
        }
        # barCode
        # fullName
        # seoName
        # metaTitle
        # metaDescription
        # metaKeywords
        # canonicalTags
        # shortDescription
        # description
        # notes
        # brandLogo
        # brandId
        # brandRecordId
        # subBrand
        # subBrandId
        # attributeSet
        # uom
        # uomValue
        # seoAvailability
        # deliveryMessage
        # stockAvailabilityMessage
        # fulfilFromWarehouse
        # fulfilFromStore
        # fulfilFromSupplier
        # fulfilFromWarehouseDays
        # fulfilFromSupplierDays
        # fulfilFromInstoreDays
        # reviewCount
        # displayTemplate
        # isDiscontinued
        # isVisible
        # couponProvider
        # subscriptionPlanType
        # soldIndependently
        # componentsInStock
        # displayInBasket
        # launchDate
        # cutOffTime
        # supplierCutoffTime
        # deliveryETA
        # videos {
        #   productId
        #   recordId
        #   url
        #   isDefault
        #   displayOrder
        # }
        # pdFs {
        #   productId
        #   recordId
        #   url
        #   name
        #   displayOrder
        # }
        # dimensions {
        #   weight
        #   height
        #   length
        #   width
        # }
        # collections {
        #   collectionId
        #   name
        # }
        # promotions {
        #   promoCode
        #   name
        #   badgeDisplay
        #   badgeLabel
        #   badgePriority
        #   badgeCSS
        # }
        # reviews {
        #   title
        #   rating
        #   comment
        #   postedBy
        #   postedOn
        #   nickName
        #   sections {
        #     id
        #     sectionName
        #     options {
        #       id
        #       name
        #       isChecked
        #       parentItemValue
        #     }
        #   }
        # }
        # freeProducts {
        #   id
        #   recordId
        #   name
        #   slug
        #   brand
        #   subBrand
        #   shortDescription
        #   description
        #   stockCode
        #   images {
        #     name
        #     tag
        #     image
        #     imageTypes
        #     url
        #     mobileUrl
        #     alt
        #     link
        #     description
        #     subTitle
        #     linkType
        #     buttonText
        #     eventCategory
        #     eventAction
        #     gaValue
        #     isActive
        #     cssClass
        #     title
        #     displayOrder
        #     imageTags {
        #       recordId
        #       src
        #       text
        #       name
        #       link
        #       shapes {
        #         type
        #         units
        #         geometry {
        #           x
        #           y
        #           width
        #           height
        #         }
        #       }
        #       productType
        #     }
        #   }
        #   image
        #   currentStock
        # }
      }
    }
  }
`

const getBasketPromotionQuery = gql`
  query getBasketPromotions(
    $basketId: String!
    $recordId: String
    $isChannelStore: Boolean
  ) {
    getBasketPromotions(
      basketId: $basketId
      recordId: $recordId
      isChannelStore: $isChannelStore
    ) {
      availablePromotions {
        promotionId
        promoType
        name
        code
        fromDate
        toDate
        additionalInfo1
        additionalInfo2
        additionalInfo3
        additionalInfo4
        additionalInfo5
        additionalInfo6
        additionalInfo7
        additionalInfo8
        additionalInfo9
        additionalInfo10
        useWithOtherDiscountCode
        isCROEnabled
        croMessage
        croSuccessMessage
        status
        paymentGatewayCsv
      }
      applicablePromotions {
        promotionId
        promoType
        name
        code
        fromDate
        toDate
        additionalInfo1
        additionalInfo2
        additionalInfo3
        additionalInfo4
        additionalInfo5
        additionalInfo6
        additionalInfo7
        additionalInfo8
        additionalInfo9
        additionalInfo10
        useWithOtherDiscountCode
        isCROEnabled
        croMessage
        croSuccessMessage
        status
        paymentGatewayCsv
      }
    }
  }
`

export class ProductService {
  static async retrieveProducts(query: IProductsPayload) {
    try {
      const res = await graphQLRequestWithApollo({
        query: productsQuery,
        variables: {
          filters: query.filters,
          sortBy: query.sortBy,
          sortOrder: query.sortOrder,
          currentPage: query.currentPage,
          collectionId: query.collectionId,
          categoryId: query.categoryId,
          slug: query.slug,
        },
      })
      return res
    } catch (err: any) {
      throw new Error(err)
    }
  }

  static async retrieveProduct(query: IGetProductPayload) {
    try {
      const res = await graphQLRequestWithApollo({
        query: getProductQuery,
        variables: {
          slug: query.slug,
          isPDP: query.isPDP,
          postCode: query.postCode,
          isChannelStore: query.isChannelStore,
        },
      })
      return res
    } catch (err: any) {
      throw new Error(err)
    }
  }

  static async retrieveProductRestData(recordId: string) {
    try {
      const res = await graphQLRequestWithApollo({
        query: getProductRestDataQuery,
        variables: {
          recordId,
        },
      })
      return res.data.getProductRestData
    } catch (err: any) {
      throw new Error(err)
    }
  }

  static async retrieveQuickViewProduct(query: IGetProductPayload) {
    try {
      const res = await graphQLRequestWithApollo({
        query: getQuickViewProductQuery,
        variables: {
          slug: query.slug,
          isPDP: query.isPDP,
          postCode: query.postCode,
          isChannelStore: query.isChannelStore,
        },
      })
      return res
    } catch (err: any) {
      throw new Error(err)
    }
  }

  static async getBasketPromotions(
    basketId: string,
    recordId?: string,
    isChannelStore?: boolean
  ): Promise<IGetBasketPromotionResponse> {
    try {
      const res = await graphQLRequestWithApollo({
        query: getBasketPromotionQuery,
        variables: {
          basketId,
          recordId,
          isChannelStore,
        },
      })
      return res?.data?.getBasketPromotions
    } catch (err: any) {
      throw new Error(err)
    }
  }
}
