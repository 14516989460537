import { IBasketPromos } from '@components/common/utils/models/cart/convenience.model'
import { useUI } from '@components/ui/context'

interface IProps {
  basketPromos: IBasketPromos
  isAppLoading: boolean
}

const AvailablePromotions = (props: IProps) => {
  const { basketPromos, isAppLoading } = props
  const { cartItems } = useUI()

  return (
    <>
      {basketPromos?.availablePromotions?.length > 0 && (
        <div className="p-6 pb-20">
          <div className="flex flex-col w-full">
            <div className="flex flex-col mb-4">
              <div className="grid grid-cols-1">
                <div>
                  <h4 className="text-lg font-semibold text-dark-brown">
                    More Offers
                  </h4>
                </div>
                {basketPromos?.availablePromotions?.map(
                  (promo: any, idx: number) => {
                    const checkNotApplied = cartItems?.promotionsApplied?.find(
                      (val: any) => val?.promoCode === promo?.code
                    )
                    if (checkNotApplied) {
                      return null
                    }
                    return (
                      <>
                        <div key={idx} className="mt-3 border border-gray-200">
                          <div className="grid grid-cols-12">
                            <div className="relative col-span-1 text-white bg-gray-500 coupon-code-panel coupon-cross-gray">
                              <h3 className="font-semibold text-white uppercase coupon-text-rotate">
                                {promo?.additionalInfo7}
                              </h3>
                            </div>
                            <div className="col-span-11 p-4 coupon-code-data">
                              <div className="flex justify-between">
                                <h3 className="mb-1 text-sm font-semibold text-black uppercase">
                                  {promo?.code}
                                </h3>
                                <button
                                  type="button"
                                  disabled={isAppLoading}
                                  className="text-sm font-semibold text-gray-300"
                                >
                                  Apply
                                </button>
                              </div>
                              {promo?.croMessage && (
                                <div className="relative my-1 m-offer-info bg-sky-offer offer-m-sec text-secondary-full-opacity pl-10-view">
                                  {promo.croMessage}
                                </div>
                              )}
                              <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                                <p className="text-xs font-normal text-gray-400">
                                  {promo?.additionalInfo6}
                                </p>
                              </div>
                              {promo?.promoType == 9 ? (
                                <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                                  <p className="text-xs font-normal text-gray-400">
                                    Applying this coupon will make all items in
                                    the cart EXCHANGE ONLY
                                  </p>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AvailablePromotions
