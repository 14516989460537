const CartIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.25C9.94043 2.25 8.25 3.94043 8.25 6V6.75H4.54688L4.5 7.45312L3.75 20.9531L3.70312 21.75H20.2969L20.25 20.9531L19.5 7.45312L19.4531 6.75H15.75V6C15.75 3.94043 14.0596 2.25 12 2.25ZM12 3.75C13.2422 3.75 14.25 4.75781 14.25 6V6.75H9.75V6C9.75 4.75781 10.7578 3.75 12 3.75ZM5.95312 8.25H8.25H9.75H14.25H15.75H18.0469L18.7031 20.25H5.29688L5.95312 8.25Z"
        fill="black"
      />
    </svg>
  )
}

export default CartIcon
