const RedCart = ({ ...props }) => {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M0 4.5V18.75H3.11719C3.45117 20.0391 4.61133 21 6 21C7.38867 21 8.54883 20.0391 8.88281 18.75H15.8672C16.2012 20.0391 17.3613 21 18.75 21C20.1387 21 21.2988 20.0391 21.6328 18.75H24V12.6328L23.9531 12.5156L22.4531 8.01562L22.2891 7.5H15.75V4.5H0ZM1.5 6H14.25V17.25H8.88281C8.54883 15.9609 7.38867 15 6 15C4.61133 15 3.45117 15.9609 3.11719 17.25H1.5V6ZM11.4609 7.71094L7.5 11.6719L5.03906 9.21094L3.96094 10.2891L6.96094 13.2891L7.5 13.8047L8.03906 13.2891L12.5391 8.78906L11.4609 7.71094ZM15.75 9H21.2109L22.5 12.8438V17.25H21.6328C21.2988 15.9609 20.1387 15 18.75 15C17.3613 15 16.2012 15.9609 15.8672 17.25H15.75V9ZM6 16.5C6.83789 16.5 7.5 17.1621 7.5 18C7.5 18.8379 6.83789 19.5 6 19.5C5.16211 19.5 4.5 18.8379 4.5 18C4.5 17.1621 5.16211 16.5 6 16.5ZM18.75 16.5C19.5879 16.5 20.25 17.1621 20.25 18C20.25 18.8379 19.5879 19.5 18.75 19.5C17.9121 19.5 17.25 18.8379 17.25 18C17.25 17.1621 17.9121 16.5 18.75 16.5Z" 
        fill="#C10000"
      />
    </svg>
  )
}
  
export default RedCart;
  