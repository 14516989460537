const GreyReturnExchangesIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M6 0C2.69141 0 0 2.69141 0 6C0 7.53516 0.578125 8.9375 1.53125 10H0.5V11H3.5V8H2.5V9.5625C1.57812 8.65625 1 7.39648 1 6C1 3.23242 3.23242 1 6 1V0ZM8.5 1V4H9.5V2.4375C10.4238 3.3418 11 4.59961 11 6C11 8.76758 8.76758 11 6 11V12C9.30859 12 12 9.30859 12 6C12 4.46094 11.4102 3.06055 10.4531 2H11.5V1H8.5Z"
        fill="black"
        fill-opacity="0.64"
      />
    </svg>
  )
}

export default GreyReturnExchangesIcon
