import { recordGA4Event } from '@components/services/analytics/ga4'
import { EmptyGuid } from '@components/utils/constants'
import {
  CURRENT_PAGE,
  getCategories,
  getCurrentPage,
  getLineItemSize,
} from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'

declare const window: any

export const handleGAEventApplyCoupon = ({
  couponName,
  header,
  discountApplied,
  user,
}: {
  couponName: string
  header: string
  discountApplied: string
  user: any
}) => {
  recordGA4Event(window, GA_EVENT.APPLY_COUPON, {
    coupon_name: couponName,
    header,
    selected_coupon: 'Single',
    // discount_applied: discountApplied,
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
  })
}

export const handleGAEventReferralSuccess = ({
  couponCode,
  user,
}: {
  couponCode?: string
  user: any
}) => {
  const currentPage = getCurrentPage()
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.REFERRAL_SUCCESS, {
      current_page: currentPage,
      referral_code: couponCode ?? '',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const handleGAEventCloseButton = (user: any) => {
  const previousPage = getCurrentPage()
  if (previousPage) {
    recordGA4Event(window, GA_EVENT.CLOSE_BUTTON, {
      current_page: CURRENT_PAGE.VIEW_COUPON,
      previous_page: previousPage,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const handleGAEventBackButton = (user: any) => {
  const previousPage = getCurrentPage()
  if (previousPage) {
    recordGA4Event(window, GA_EVENT.BACK_BUTTON, {
      current_page: CURRENT_PAGE.VIEW_COUPON,
      previous_page: previousPage,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const handleGAEventHaveReferralCode = (user: any) => {
  const currentPage = getCurrentPage()
  if (currentPage) {
    recordGA4Event(window, GA_EVENT.HAVE_REFERRAL_CODE, {
      current_page: currentPage,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const viewCoupons = (
  promo: any,
  items: any,
  user: any,
  page: string
) => {
  recordGA4Event(window, GA_EVENT.VIEW_PROMOTION, {
    ecommerce: {
      items: items?.lineItems?.map((item: any, itemId: number) => ({
        item_name: item?.name,
        item_brand: item?.brand,
        item_category2: getCategories(item).category2,
        item_category: getCategories(item).category,
        item_variant: item?.colorName,
        color: item?.colorName,
        quantity: item?.qty,
        item_id: item?.sku,
        item_size: getLineItemSize(
          item?.stockCode
            ?.toUpperCase()
            ?.substring(item?.stockCode?.lastIndexOf('-') + 1),
          item?.variantProductsAttribute
        ),
        price: item?.price?.raw?.withTax,
        item_var_id: item?.stockCode,
        item_list_name: getCategories(item).category,
        index: itemId,
      })),
      coupon_list: [
        promo?.map((promo: any, promoId: number) => ({
          coupon_name: promo?.name,
          coupon_value: promo?.additionalInfo6,
        })),
      ],
      current_page: page,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    },
  })
}
