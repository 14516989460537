const DownArrowFilled = ({ ...props }) => {
  return (
    <svg
      width={props?.width ? props?.width : '12'}
      height={props?.height ? props?.height : '6'}
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.666992 0L6.00033 5.33333L11.3337 0H0.666992Z" fill="black" />
    </svg>
  )
}

export default DownArrowFilled
