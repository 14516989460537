import { useState } from 'react'
import Router, { useRouter } from 'next/router'
import BasketExpressCheckout from './BasketExpressCheckout'
import {
  GENERAL_PLACE_ORDER,
  GENERAL_TOTAL,
} from '@components/utils/textVariables'
import { matchStrings, priceFormat } from '@framework/utils/parse-util'
import AlertRibbon from '@components/ui/AlertRibbon'
import { LoadingDots, useUI } from '@components/ui'
import {
  API_STATUS,
  CheckoutType,
  EmptyGuid,
  FacebookEvents,
} from '@components/utils/constants'
import { Cookie, IMAGE_ASSET_BASE_URL, NEXT_PUBLIC_IS_GOKWIK_CHECKOUT_ENABLED } from '@framework/utils/constants'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { GA_EVENT } from 'hooks/ga_event.modal'
import {
  eventIdForFB,
  getBrowserIdForFBConversion,
  getCategories,
  getCurrentPage,
  getDateOfBirthHash,
  getGenderHash,
  getIpAddressForFBConversion,
  getKwikPassFields,
  openLoginView,
} from '@framework/utils/app-util'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import {
  LocalStorage,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import { getItem } from '@components/utils/localStorage'
import { isAllItemsInStock } from './helper'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { getSHA256Hash } from '@framework/utils/cipher'
import { generatePriceDetailsSection } from '@components/MobileCart/helper'
import { IDeviceInfo } from '@components/ui/context'
import { IConvenienceData } from '@components/common/utils/models/cart/convenience.model'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { getSessionItem } from '@components/utils/sessionStorage'
import Cookies from 'js-cookie'

interface ICartFooter {
  isEmpty: boolean
  cartItems: any
  reValidateData: any
  showRemove: (products: Array<any> | any) => void
  savedUPIs: Array<any> | undefined
  deviceInfo: IDeviceInfo
  setBasketReValidate: (prop: any) => void
  preferredPaymentMethod: any
  config: any
  savedCards: Array<any> | undefined
  isUserLoggedIn: boolean
  fetchBasketReValidate: () => void
  convenienceData: IConvenienceData
  setConvenienceFee: any
}

const CartFooter = (props: ICartFooter) => {
  const {
    isEmpty,
    cartItems,
    reValidateData,
    showRemove,
    savedUPIs,
    deviceInfo,
    setBasketReValidate,
    preferredPaymentMethod,
    config,
    savedCards,
    isUserLoggedIn,
    fetchBasketReValidate,
    convenienceData,
    setConvenienceFee,
  } = props
  const [validatedUserAPIStatus, setValidatedUserAPIStatus] =
    useState<API_STATUS>(API_STATUS.NOT_STARTED)
  const { setLoginCustomTitle, setSidebarView, user, displayAlert } = useUI()
  const router = useRouter()
  const isChannelStore = router?.query?.channelStore ? true : false
  const { asPath } = Router
  const trackMoEngageEvent = useMoEngageEvent()
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()

  const freeItem =
    cartItems?.lineItems?.findIndex(
      (item: any) => item?.price?.raw?.withTax == 0
    ) != -1
      ? true
      : false

  const checkoutDisplayType = () => {
    let type = CheckoutType.NONE

    if (savedUPIs != undefined && savedCards != undefined) {
      if (preferredPaymentMethod && preferredPaymentMethod?.method) {
        const isUPI =
          preferredPaymentMethod?.method &&
          matchStrings(
            preferredPaymentMethod?.method,
            PaymentMethodType.UPI,
            true
          ) &&
          preferredPaymentMethod?.key
        const isCard =
          preferredPaymentMethod?.method &&
          matchStrings(
            preferredPaymentMethod?.method,
            PaymentMethodType.CARD,
            true
          )

        if (isUPI) {
          if (preferredPaymentMethod?.key) {
            // VPA Id is saved
            const findSavedUPI = savedUPIs?.length
              ? savedUPIs?.find((x: any) =>
                  matchStrings(x?.upiId, preferredPaymentMethod?.key, true)
                )
              : null
            type = findSavedUPI?.upiId
              ? CheckoutType.EXPRESS
              : CheckoutType.NORMAL
          } else {
            // UPI (without VPA Id, using mWeb)
            type = CheckoutType.EXPRESS
          }
        } else if (isCard) {
          const savedCardIsIn = preferredPaymentMethod?.key
          const findSavedCard = savedCards?.length
            ? savedCards?.find((x: any) =>
                matchStrings(x?.card_isin, savedCardIsIn, true)
              )
            : null
          type = findSavedCard?.card_isin
            ? CheckoutType.EXPRESS
            : CheckoutType.NORMAL
        } else {
          type = CheckoutType.NORMAL // Consider anything apart from UPI or Card AS NORMAL CHECKOUT.
        }
      } else {
        type = CheckoutType.NORMAL
      }
    }
    return type
  }

  const beginCheckout = async (cartItems: any) => {
    const eventId = eventIdForFB()
    const items = cartItems?.lineItems?.map((item: any, itemId: number) => ({
      item_name: item?.name,
      item_brand: item?.brand,
      item_category2: getCategories(item).category2,
      item_category: getCategories(item).category,
      item_variant: item?.colorName,
      color: item?.colorName,
      quantity: item?.qty,
      item_id: item?.sku,
      item_size: JSON.parse(item?.attributesJson)?.Size?.toUpperCase() || '',
      price: item?.price?.raw?.withTax,
      item_var_id: item?.stockCode,
      item_list_name: getCategories(item).category,
      index: itemId,
    }))
    if (isChannelStore) {
      trackGoogleAnalyticsEvent(
        GA_EVENT.PPG_BEGIN_CHECKOUT,
        {
          ecommerce: {
            items: items,
            current_page: 'Checkout',
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            paymode: '',
            address: '',
            promotion_name: cartItems?.promotionsApplied
              ?.map((promo: any) => promo?.name)
              ?.join(','),
            promotion_promocode: cartItems?.promotionsApplied
              ?.map((promo: any) => promo?.promoCode)
              ?.join(','),
            event_id: eventId,
            value: cartItems?.grandTotal?.raw?.withTax,
          },
        },
        window
      )
    } else {
      const gaPayload = {
        ecommerce: {
          items: items,
          current_page: 'Checkout',
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
          paymode: '',
          address: '',
          promotion_name: cartItems?.promotionsApplied
            ?.map((promo: any) => promo?.name)
            ?.join(','),
          promotion_promocode: cartItems?.promotionsApplied
            ?.map((promo: any) => promo?.promoCode)
            ?.join(','),
          event_id: eventId,
          value: cartItems?.grandTotal?.raw?.withTax,
          item_var_id: cartItems?.lineItems[0]?.stockCode,
          ...getKwikPassFields(),
        },
      };
      recordGA4Event(window, GA_EVENT.BEGIN_CHECKOUT, gaPayload)
    }

    const mobileNumber = user?.mobile || user?.telephone
    let facebookeventData: any = {
      data: [
        {
          event_name: FacebookEvents.INITIATE_CHECKOUT,
          event_time: Math.floor(new Date().getTime() / 1000),
          event_id: eventId,
          action_source: 'website',
          user_data: {
            em: [user?.email ? await getSHA256Hash(user?.email) : ''],
            ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
            fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
            ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
            client_user_agent: window.navigator.userAgent,
            fbp: getBrowserIdForFBConversion(),
            client_ip_address: (await getIpAddressForFBConversion()) || null,
            external_id: mobileNumber
              ? await getSHA256Hash(mobileNumber)
              : null,
            db: await getDateOfBirthHash(user),
            ge: await getGenderHash(user?.gender),
            ct: deliveryAddress?.city
              ? await getSHA256Hash(
                  deliveryAddress?.city?.substring(0, 2)?.toLowerCase()
                )
              : null,
            zp: deliveryAddress?.postCode
              ? await getSHA256Hash(deliveryAddress?.postCode)
              : null,
            country: await getSHA256Hash('in'),
          },
          custom_data: {
            currency: 'INR',
            num_items: cartItems?.lineItems?.length,
            content_name: getCurrentPage(),
            value: cartItems?.grandTotal?.raw?.withTax,
            content_ids: cartItems?.lineItems?.map(
              (item: any) => item?.stockCode
            ),
            content_type: 'product',
            contents: cartItems?.lineItems?.map((item: any) => ({
              id: item?.productId,
              quantity: item?.qty,
              item_price: item?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            })),
          },
        },
      ],
    }

    facebookEventHandler(facebookeventData)

    let couponStatus = false
    if (cartItems?.promotionsApplied?.length > 0) {
      couponStatus = true
    }
    const begin_checkout = {
      URL: window?.location?.href,
      cart_amount: cartItems?.grandTotal?.raw?.withTax || '',
      cart_quantity: cartItems?.lineItems?.length || '',
      coupon_status: couponStatus,
      coupon_label: cartItems?.promotionsApplied[0]?.promoCode || '',
      Product_name: cartItems?.lineItems?.map((item: any) => item?.name || ''),
      Product_ID: cartItems?.lineItems?.map(
        (item: any) => item?.stockCode || ''
      ),
      Price: cartItems?.lineItems?.map(
        (item: any) => item?.price?.raw?.withTax || ''
      ),
      Product_color:
        cartItems?.lineItems?.map((item: any) => item.colorName) || '',
      Product_category_2: cartItems?.lineItems?.map(
        (item: any) => item?.categoryItems[0]?.categoryName || ''
      ),
      Product_category: cartItems?.lineItems?.map((item: any) =>
        item?.categoryItems.length
          ? item?.categoryItems[item?.categoryItems?.length - 1]?.categoryName
          : ''
      ),
      Quantity: cartItems?.lineItems?.map((item: any) => item?.qty || ''),
      Product_size: cartItems?.lineItems?.map(
        (item: any) =>
          item?.variantProducts
            ?.find(
              (variantProductDatum: any) =>
                variantProductDatum?.stockCode === item?.stockCode
            )
            ?.variantAttributes?.find(
              (variantAttributeDatum: any) =>
                variantAttributeDatum?.fieldCode === 'clothing.size'
            )?.fieldValue || ''
      ),
    }
    trackMoEngageEvent(MO_ENGAGE_EVENT.BEGIN_CHECKOUT, begin_checkout, window)
  }

  const getTotalMRP = () => {
    const mrp = cartItems?.lineItems?.map((item: any) => {
      if (item?.price?.raw?.withTax !== 0) {
        return item?.listPrice?.raw?.withTax * item?.qty
      } else {
        return 0
      }
    })
    const total = mrp?.reduce(
      (sum: number, current: number) => sum + current,
      0
    )
    return total
  }

  const getDiscountOnMrp = () => {
    const discount = cartItems?.lineItems?.map((item: any) => {
      if (item?.price?.raw?.withTax !== 0) {
        return item?.price?.raw?.withTax * item?.qty
      } else {
        return 0
      }
    })
    const discountOnMrp =
      totalMRP -
      discount?.reduce((sum: number, current: number) => sum + current, 0)
    return discountOnMrp
  }

  const getDeliveryAddress = () => {
    const address = getItem(LocalStorage.Key.DELIVERY_ADDRESS)
    if (typeof address === 'string') {
      return JSON.parse(address)
    } else if (!!address) {
      return address
    }
    return null
  }

  const totalMRP: any = getTotalMRP()
  const discountOnMRP: any = getDiscountOnMrp()
  const deliveryAddress = getDeliveryAddress()

  const normalCheckout = (
    <>
      <div className="p-4 mt-0 sm:p-6">
        <button
          className="cursor-pointer w-full flex items-center justify-between px-6 py-2 font-medium text-white bg-black border-2 border-black rounded-sm shadow-sm cursor-pointer hover:text-white hover:bg-gray-900 disabled:bg-opacity-50 disabled:border-opacity-5"
          onClick={async () => {
            setValidatedUserAPIStatus(API_STATUS.LOADING)
            // const isAuthorized =
            //   localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
            if (NEXT_PUBLIC_IS_GOKWIK_CHECKOUT_ENABLED) {
              if (
                isUserLoggedIn
                // && isAuthorized
              ) {
                // Validate basket
                const reValidateResult = await fetchBasketReValidate()
                Cookies.set(Cookie.Key.IS_BC_USER, 'true')
                Cookies.set(Cookie.Key.LOGIN_POPUP, 'false')
                if (isAllItemsInStock(reValidateResult)) {
                  const messageLength: number = reValidateData?.message
                    ? JSON.parse(reValidateData?.message)?.length ?? 0
                    : 0

                  if (messageLength == 0) {
                    // No error while basket validation
                    const deliveryAddress = getDeliveryAddress()
                    localStorage.removeItem('routeRedirectionToCart')
                    Router.push('/checkout')
                    beginCheckout(cartItems)
                    setValidatedUserAPIStatus(API_STATUS.SUCCESS)
                  }
                }
              } else {
                const reValidateResult = await fetchBasketReValidate()
                Cookies.set(Cookie.Key.IS_BC_USER, 'false')
                if (isAllItemsInStock(reValidateResult)) {
                  const messageLength: number = reValidateData?.message
                    ? JSON.parse(reValidateData?.message)?.length ?? 0
                    : 0

                  if (messageLength == 0) {
                    // No error while basket validation
                    // setLoginCustomTitle('Login to Proceed')
                    beginCheckout(cartItems)
                    localStorage.removeItem('routeRedirectionToCart')
                    if (window && typeof window.handleCustomLogin === 'function') {
                      window.handleCustomLogin(true);
                    }
                    // openLoginView(asPath)
                    setTimeout(() => {
                      // setSidebarView('NEW_LOGIN_VIEW')
                      setValidatedUserAPIStatus(API_STATUS.SUCCESS)
                    }, 1000)
                  }
                }
              }
            } else {
              // Validate basket
              const reValidateResult = await fetchBasketReValidate()

              if (isAllItemsInStock(reValidateResult)) {
                const messageLength: number = reValidateData?.message
                  ? JSON.parse(reValidateData?.message)?.length ?? 0
                  : 0

                if (messageLength == 0) {
                  // No error while basket validation
                  const deliveryAddress = getDeliveryAddress()
                  Router.push('/checkout')
                  beginCheckout(cartItems)
                  setValidatedUserAPIStatus(API_STATUS.SUCCESS)
                }
              }
            }
          }}
          disabled={validatedUserAPIStatus === API_STATUS.LOADING}
        >
          {validatedUserAPIStatus === API_STATUS.LOADING ? (
            <span className="p-2 w-full">
              <LoadingDots />
            </span>
          ) : (
            <>
              <div className="flex flex-col">
                <span className="text-sm font-semibold">
                  {priceFormat(cartItems.grandTotal?.raw?.withTax)}
                </span>
                <span className="block text-sm font-light">
                  {GENERAL_TOTAL}
                </span>
              </div>
              <div>
                {GENERAL_PLACE_ORDER}
                <span className="ml-2 sprite-icon arrow-right-white" />
              </div>
            </>
          )}
        </button>
      </div>
    </>
  )

  return (
    <>
      {!isEmpty && (
        <>
          <div className="flex flex-col px-4 sm:px-8">
            {generatePriceDetailsSection({
              totalMRP,
              discountOnMRP,
              cartItems,
              freeItem,
              setConvenienceFee,
              convenienceData,
              isChannelStore,
            })}
          </div>
          <div className="sticky bottom-0 z-50 p-0 mt-2 bg-white border-t border-gray-200 shadow sm:px-0">
            {displayAlert ? (
              <div className="sm:hidden">
                <AlertRibbon />
              </div>
            ) : null}

            {reValidateData?.message != null &&
            JSON.parse(reValidateData?.message).length > 0 ? (
              <>
                <div className="flex flex-col px-8 py-2 bg-red-200">
                  <div className="flex text-xs font-semibold text-left text-red-500">
                    <span className="relative mr-1 top-1">
                      <img
                        alt="EDD"
                        src={`${IMAGE_ASSET_BASE_URL}/icons/not-shipped-edd.svg`}
                        width={20}
                        height={20}
                        className="relative inline-block mr-1 top-2"
                      />
                    </span>
                    <span className="mt-2">
                      {JSON.parse(reValidateData?.message).length} Item in your
                      basket is sold out!
                    </span>
                  </div>
                  <div className="flex justify-start gap-8">
                    <button
                      type="button"
                      className="pt-3 font-semibold text-black text-12"
                      onClick={() => {
                        const warningItems: Array<{
                          Key: string
                          Value: number
                        }> = JSON.parse(reValidateData?.message)
                        if (warningItems?.length) {
                          const deleteProducts = cartItems?.lineItems?.filter(
                            (x: any) =>
                              warningItems
                                ?.map((y: any) => y.Key)
                                .includes(x?.stockCode)
                          )
                          if (deleteProducts?.length) {
                            showRemove(deleteProducts)
                          }
                        }
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {!checkoutDisplayType() ? (
              <div className="flex justify-between w-full py-2">
                <LoadingDots />
              </div>
            ) : (
              <>
                {checkoutDisplayType() === CheckoutType.EXPRESS &&
                deliveryAddress?.id &&
                !deliveryAddress?.storeCode ? (
                  <>
                    {/* Show express checkout*/}
                    <BasketExpressCheckout
                      preferredPaymentMethod={preferredPaymentMethod}
                      config={config}
                      savedCard={savedCards?.find((x: any) =>
                        matchStrings(x?.card_isin, preferredPaymentMethod?.key)
                      )}
                      reValidateData={reValidateData}
                      setBasketReValidate={setBasketReValidate}
                      deviceInfo={deviceInfo}
                    />
                  </>
                ) : (
                  <>
                    {/* Show normal checkout*/}
                    {normalCheckout}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default CartFooter
