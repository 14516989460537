interface IProps {
  multiSelectPromo: Array<any>
  multiPromo: Array<any>
  handleMultiPromo: (val: any) => void
}

const MultiSelectPromos = (props: IProps) => {
  const { multiSelectPromo, multiPromo, handleMultiPromo } = props

  return (
    <>
      {multiSelectPromo?.length > 0 && (
        <>
          <div className="flex flex-col mb-4">
            <div className="grid grid-cols-1">
              <div>
                <h4 className="text-lg font-semibold text-dark-brown">
                  Select and apply multiple coupons
                </h4>
              </div>
              {multiSelectPromo?.map((promo: any, idx: number) => {
                return (
                  <>
                    <div key={idx} className="mt-3 border border-gray-200">
                      <div className="grid grid-cols-12">
                        <div className="relative col-span-1 text-white bg-orange-500 coupon-code-panel coupon-cross">
                          <h3 className="font-semibold text-white uppercase coupon-text-rotate">
                            {promo?.additionalInfo7}
                          </h3>
                        </div>
                        <div className="col-span-11 p-4 coupon-code-data">
                          <div className="flex justify-between">
                            <h3 className="mb-1 text-sm font-semibold text-black uppercase">
                              {promo?.code}
                            </h3>
                            <div className="font-medium text-left text-black text-md">
                              <input
                                type="checkbox"
                                className="custom-checkbox-promo"
                                checked={multiPromo?.includes(promo)}
                                onChange={() => handleMultiPromo(promo)}
                                value={promo?.code}
                                name="applyPromo"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <>
                              {promo?.croSuccessMessage != '' ? (
                                <p className="text-xs font-medium text-emerald-500">
                                  {promo?.croSuccessMessage}
                                </p>
                              ) : (
                                <>
                                  <p className="text-xs font-medium text-emerald-500">
                                    {promo?.name}
                                  </p>
                                </>
                              )}
                            </>
                          </div>
                          <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                            <p className="text-xs font-normal text-gray-400">
                              {promo?.additionalInfo6}
                            </p>
                          </div>
                          {promo?.promoType == 9 ? (
                            <div className="flex flex-col pt-4 mt-4 border-t border-gray-200 border-dotted px">
                              <p className="text-xs font-normal text-gray-400">
                                Applying this coupon will make all items in the
                                cart EXCHANGE ONLY
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default MultiSelectPromos
