import {
  NewCouponIcon,
  PromoGreenStrip,
  PromoOrangeStrip,
} from '@components/icons'
import PromotionDetailPopup from '@components/men/PromotionDetailPopup'
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import { handleBrokenImageError } from '@framework/utils/app-util'
import imagePlaceHolder from 'public/imagePlaceHolder.jpg'
import { useState } from 'react'

const PromotionOfferInfo = (props: any) => {
  const {
    promoName,
    promoDesc,
    promoDetails,
    btnText,
    isApplicable,
    progressPercent,
    onSubmit,
    img,
    deviceInfo,
    previousPath,
  } = props
  const [showPromoDetails, setShowPromoDetails] = useState<boolean>(false)

  return (
    <>
      <div
        className={`promo-box flex  ${
          isApplicable ? 'light-green-color' : ''
        }  mt-[10px] mb-[10px] mr-[9px] border-[1px solid #25100014] rounded-sm`}
        onClick={() => {
          setShowPromoDetails(true)
        }}
      >
        <div
          className={`col-span-1 text-left flex items-center  ${
            isApplicable ? 'bg-[#00890E]' : 'bg-[#EC5E28]'
          }  text-white rounded-l-sm`}
        >
          <h3 className="text-[10px] flex whitespace-nowrap justify-center w-[20px] font-semibold text-white uppercase -rotate-90">
            {promoName}
          </h3>
          {isApplicable ? <PromoGreenStrip /> : <PromoOrangeStrip />}
        </div>

        <div className="flex justify-between gap-2 flex-col py-[10px] px-3 w-full">
          <div className="flex justify-between min-h-[40px]">
            <span
              className="text-sm text-left font-medium text-black text-truncate-two-lines pr-2"
              style={{ flex: '1 0 90%' }}
            >
              {promoDesc}
            </span>
            {img ? (
              <div style={{ flex: '1 0 10%' }}>
                <img
                  src={img || IMG_PLACEHOLDER || imagePlaceHolder}
                  alt={'promo item'}
                  width={22}
                  height={36}
                  style={{
                    height: '36px',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                  onError={(e: any) => handleBrokenImageError(e)}
                  className={'couponIcon'}
                />
              </div>
            ) : (
              <NewCouponIcon width="25" height="25" />
            )}
          </div>
          <div className="h-[4px]">
            {progressPercent || progressPercent == 0 ? (
              <div className="w-full">
                <div className="h-[4px] bg-[#E9E5DF] rounded-xl">
                  <div
                    className={`h-[4px] ${
                      isApplicable ? 'bg-[#00890E]' : 'bg-[#EC5E28]'
                    }  rounded-xl `}
                    style={{ width: `${progressPercent}%` }}
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="text-left flex h-4">
            {onSubmit && btnText && (
              <a href="javascript: void(0);" className="leading-none">
                <span
                  className="text-orange-500 text-left text-[12px] font-medium"
                  onClick={(event) => {
                    event.stopPropagation()
                    onSubmit()
                  }}
                >
                  {btnText}
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
      {showPromoDetails ? (
        <PromotionDetailPopup
          promotionData={promoDetails}
          onClose={() => {
            setShowPromoDetails(false)
          }}
          deviceInfo={deviceInfo}
          previousPath={previousPath}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default PromotionOfferInfo
