// Base Imports
import { Fragment, useEffect, useState } from 'react'

// Component Imports
import SwiperCore, { Navigation } from 'swiper'
import { useUI } from '@components/ui/context'
import Router, { useRouter } from 'next/router'

//
// Other Imports
import 'swiper/css'
import 'swiper/css/navigation'
import { IExtraProps } from '@components/common/Layout/Layout'
import Promotions from './Promotions'
import { viewCoupons } from './Promotions/eventsHelper'
import { IBasketPromos } from '@components/common/utils/models/cart/convenience.model'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { EmptyGuid } from '@components/utils/constants'
import { getCurrentPage, CURRENT_PAGE } from '@framework/utils/app-util'
import axios from '@services/axiosInterceptorInstance'
import { GA_EVENT } from 'hooks/ga_event.modal'

SwiperCore.use([Navigation])

interface IPromotionInputProps {
  readonly basketPromos: IBasketPromos
  readonly paymentOffers: any | undefined
  readonly items: any
  readonly getBasketPromoses?: (val: string) => void
  readonly isChannelStore?: boolean
  previousPath: string
  readonly hideBlueStrips?: boolean
  readonly showPromoList?: boolean
}

const PromotionInput = (props: IPromotionInputProps & IExtraProps) => {
  const {
    basketPromos,
    paymentOffers,
    items,
    getBasketPromoses,
    deviceInfo,
    isChannelStore = false,
    previousPath,
    hideBlueStrips,
    showPromoList,
  } = props
  const { user } = useUI()
  const { asPath } = useRouter()
  const [isShowCoupon, setShowCoupon] = useState(false)
  const [isReferralShow, setReferralShow] = useState(false)
  const [referralErrorMsg, setReferralErrorMsg] = useState('')

  const handleCloseCoupon = (e: any) => {
    const newUrl = asPath?.replaceAll('#couponopen', '')
    const isCouponShouldOpen = asPath?.includes('#couponopen')
    Router.replace(newUrl)
    if (isShowCoupon && isCouponShouldOpen && !isChannelStore) {
      // Router.back()
    }
    setShowCoupon(e)
  }

  const handleGAEventApplyCoupon = ({
    couponName,
    header,
    discountApplied,
  }: {
    couponName: string
    header: string
    discountApplied: string
  }) => {
    recordGA4Event(window, GA_EVENT.APPLY_COUPON, {
      coupon_name: couponName,
      header,
      selected_coupon: 'Single',
      // discount_applied: discountApplied,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
  const handleGAEventReferralSuccess = (couponCode?: string) => {
    const currentPage = getCurrentPage()
    if (currentPage) {
      recordGA4Event(window, GA_EVENT.REFERRAL_SUCCESS, {
        current_page: currentPage,
        referral_code: couponCode ?? '',
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
  }
  const handleGAEventCloseButton = () => {
    const previousPage = getCurrentPage()
    if (previousPage) {
      recordGA4Event(window, GA_EVENT.CLOSE_BUTTON, {
        current_page: CURRENT_PAGE.VIEW_COUPON,
        previous_page: previousPage,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
  }
  const handleGAEventBackButton = () => {
    const previousPage = getCurrentPage()
    if (previousPage) {
      recordGA4Event(window, GA_EVENT.BACK_BUTTON, {
        current_page: CURRENT_PAGE.VIEW_COUPON,
        previous_page: previousPage,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })
    }
  }

  useEffect(() => {
    const isCouponShouldOpen = asPath?.includes('#couponopen')
    const isReferralOpen = asPath?.includes('#referralOpen')
    if (isShowCoupon || isCouponShouldOpen) {
      if (isCouponShouldOpen) {
        if (!isReferralOpen) {
          setShowCoupon(true)
          viewCoupons(
            basketPromos?.applicablePromotions,
            items,
            user,
            'View Coupon'
          )
        }
      } else {
        handleCloseCoupon(false)
      }
    }
  }, [asPath])

  useEffect(() => {
    const isReferralOpen = asPath?.includes('#referralOpen')
    if (isReferralShow || isReferralOpen) {
      if (isReferralOpen) {
        setReferralShow(true)
      } else {
        handleReferralShow(false)
      }
    }
  }, [asPath])

  const handleReferralShow = (e: boolean) => {
    const newUrl = asPath?.replaceAll('#referralOpen', '')
    const isCouponShouldOpen = asPath?.includes('#referralOpen')
    Router.replace(newUrl)
    if (isReferralShow && isCouponShouldOpen) {
      Router.back()
    }
    setReferralErrorMsg('')
    setReferralShow(e)
  }

  return (
    <Promotions
      basketPromos={basketPromos}
      paymentOffers={paymentOffers}
      items={items}
      deviceInfo={deviceInfo}
      isChannelStore={isChannelStore}
      handleCloseCoupon={handleCloseCoupon}
      handleReferralShow={handleReferralShow}
      setReferralErrorMsg={setReferralErrorMsg}
      isShowCoupon={isShowCoupon}
      isReferralShow={isReferralShow}
      referralErrorMsg={referralErrorMsg}
      getBasketPromoses={getBasketPromoses}
      previousPath={previousPath}
      hideBlueStrips={hideBlueStrips}
      showPromoList={showPromoList}
    />
  )
}
export default PromotionInput
