import { recordGA4Event } from '@components/services/analytics/ga4'
import { IDeviceInfo, useUI } from '@components/ui/context'
import {
  COUPON_CODE,
  COUPON_CODE_WITHOUT_LOGIN,
  EmptyGuid,
  NEXT_APPLY_PROMOTION,
  SHOW_APPLY_COUPON_SECTION,
} from '@components/utils/constants'
import {
  APPLY_PROMOTION,
  GENERAL_APPLY_TEXT,
} from '@components/utils/textVariables'
import {
  getCategories,
  getCurrentPage,
  getLineItemSize,
} from '@framework/utils/app-util'
import axios from '@services/axiosInterceptorInstance'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import Router, { useRouter } from 'next/router'
import { useState } from 'react'
import { useMoEngageEvent } from 'hooks/useMoengageEvent'
import ReferralInputPopup from './ReferralInputPopup'
import CouponsPanelPopup from './CouponsPanelPopup'
import { IBasketPromos } from '@components/common/utils/models/cart/convenience.model'
import MoreOfferMsg from './MoreOfferMsg'
import AppliedCouponPopup from './AppliedCouponPopup'
import { getSHA256Hash } from '@framework/utils/cipher'
import PromoList from '@components/cart/Promotions/PromoList'
import { GreenCouponIcon } from '@components/icons'
import Cookies from 'js-cookie'
import PromotionErrorMessage , { getErrorMessage } from '@components/cart/Promotions/PromotionErrorMessage'

interface IProps {
  basketPromos: IBasketPromos
  paymentOffers: any
  items: any
  deviceInfo: IDeviceInfo
  isChannelStore: boolean
  handleCloseCoupon: (val: boolean) => void
  handleReferralShow: (val: boolean) => void
  setReferralErrorMsg: (val: string) => void
  isShowCoupon: boolean
  isReferralShow: boolean
  referralErrorMsg: string
  getBasketPromoses?: (val: string) => void
  previousPath: string
  hideBlueStrips?: boolean
  showPromoList?: boolean
}

const Promotions = (props: IProps) => {
  const {
    basketPromos,
    paymentOffers,
    items,
    deviceInfo,
    isChannelStore,
    handleCloseCoupon,
    handleReferralShow,
    setReferralErrorMsg,
    isShowCoupon,
    isReferralShow,
    referralErrorMsg,
    getBasketPromoses,
    previousPath,
    hideBlueStrips,
    showPromoList,
  } = props
  const { basketId, setCartItems, cartItems, user, storeBasketId } = useUI()
  const { asPath } = useRouter()
  const [isAppLoading, setIsAppLoading] = useState(false)
  const [value, setValue] = useState('')
  const [promoCodeForErrorHandling, setPromoCodeForErrorHandling] = useState('')
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [isCouponApplied, setCouponApplied] = useState(false)
  const [appliedBasketPromo, setAppliedBasketPromo] = useState<any>()

  const { isMobile, isIPadorTablet } = deviceInfo
  const trackMoEngageEvent = useMoEngageEvent()

  const handleChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleSubmit = async (
    method: string = 'apply',
    promoCode: string = value,
    isMulti?: boolean
  ) => {
    setPromoCodeForErrorHandling(promoCode)
    if (!user?.userId && isWelcomeCoupon()) {
      setError(true)
    }
    if (!isAppLoading) {
      setIsAppLoading(true)
      try {
        const { data }: any = await axios.post(NEXT_APPLY_PROMOTION, {
          basketId: isChannelStore ? storeBasketId : basketId,
          promoCode,
          method,
          isChannelStore: isChannelStore ? true : false,
        })
        if (data?.result) {
          //setError(data?.result?.isVaild);
          setErrorCode(data?.message);
          setCartItems(data?.result?.basket)
          setValue('')
        } else {
          setError(!data?.result?.isVaild)
          if (!data?.isVaild) {
            const mobileNumber = user?.mobile || user?.telephone || user?.phone
            recordGA4Event(window, GA_EVENT.PROMO_ERROR, {
              ecommerce: {
                items: [
                  items?.lineItems?.map((item: any, itemId: number) => ({
                    item_name: item?.name,
                    item_brand: item?.brand,
                    item_category2: getCategories(item).category2,
                    item_category: getCategories(item).category,
                    item_variant: item?.colorName,
                    quantity: item?.qty,
                    item_id: item?.sku,
                    item_size: getLineItemSize(
                      item?.stockCode
                        ?.toUpperCase()
                        ?.substring(item?.stockCode?.lastIndexOf('-') + 1),
                      item?.variantProductsAttribute
                    ),
                    price: item?.price?.raw?.withTax,
                    item_var_id: item?.stockCode,
                    item_list_name: getCategories(item).category,
                    index: itemId,
                  })),
                ],
                phone_number: await getSHA256Hash(mobileNumber),
                promotion_name: promoCode,
                promotion_promocode: promoCode,
                current_page: getCurrentPage(),
                error_message: getErrorMessage(data?.message),
                loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
              },
            })
          }
          setValue('')
          setTimeout(() => {
            setError(false)
            handleCloseCoupon(false)
          }, 5000)
        }
        getBasketPromoses && getBasketPromoses(basketId)

        const {
          result: {
            basket: { promotionsApplied },
          },
        } = data
        const mobileNumber = user?.mobile || user?.telephone || user?.phone
        recordGA4Event(window, GA_EVENT.SELECT_PROMOTION, {
          ecommerce: {
            items: items?.lineItems?.map((item: any, itemId: number) => ({
              item_name: item?.name,
              item_var_id: item?.stockCode,
              price: item?.price?.raw?.withTax,
              quantity: item?.qty,
              item_brand: item?.brand,
              item_id: item?.sku,
              item_size:
                item.attributesJson && JSON.parse(item.attributesJson).Size,
              item_variant: item?.colorName,
            })),
            promotion_name: promotionsApplied[0]?.name,
            promotion_promocode: promotionsApplied[0]?.promoCode,
            selected_coupon: isMulti ? 'Multi' : 'Single',
            discount_applied: data?.result?.basket?.discount?.raw?.withTax,
            loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
            current_page: getCurrentPage(),
            phone: await getSHA256Hash(mobileNumber),
          },
        })

        trackMoEngageEvent(
          MO_ENGAGE_EVENT.CART_COUPON,
          {
            URL: window?.location?.href,
            label: promoCode,
            discount_applied: data?.result?.basket?.discount?.raw?.withTax || 0,
          },
          window
        )
        setIsAppLoading(false)
        return data?.result?.isVaild ?? false
      } catch (error) {
        setIsAppLoading(false)
      }
      return false
    }
  }

  const handleSubmitInput = async (
    method: string = 'apply',
    promoCode: string = value
  ) => {
    setPromoCodeForErrorHandling(promoCode)
    if (!user?.userId && isWelcomeCoupon()) {
      setError(true)
      return false
    }
    if (!isAppLoading) {
      setIsAppLoading(true)
      try {
        const { data }: any = await axios.post(NEXT_APPLY_PROMOTION, {
          basketId: isChannelStore ? storeBasketId : basketId,
          promoCode,
          method,
          isChannelStore: isChannelStore ? true : false,
        })
        if (data?.result) {
          //setError(data?.result?.isVaild);
          setErrorCode(data?.message);
          setCartItems(data?.result?.basket)
          setValue('')
        } else {
          if (!data?.isVaild) {
            const mobileNumber = user?.mobile || user?.telephone || user?.phone
            recordGA4Event(window, GA_EVENT.PROMO_ERROR, {
              ecommerce: {
                items: [
                  items?.lineItems?.map((item: any, itemId: number) => ({
                    item_name: item?.name,
                    item_brand: item?.brand,
                    item_category2: getCategories(item).category2,
                    item_category: getCategories(item).category,
                    item_variant: item?.colorName,
                    quantity: item?.qty,
                    item_id: item?.sku,
                    item_size: getLineItemSize(
                      item?.stockCode
                        ?.toUpperCase()
                        ?.substring(item?.stockCode?.lastIndexOf('-') + 1),
                      item?.variantProductsAttribute
                    ),
                    price: item?.price?.raw?.withTax,
                    item_var_id: item?.stockCode,
                    item_list_name: getCategories(item).category,
                    index: itemId,
                  })),
                ],
                phone_number: await getSHA256Hash(mobileNumber),
                promotion_name: promoCode,
                promotion_promocode: promoCode,
                current_page: getCurrentPage(),
                error_message: getErrorMessage(data?.message),
                loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
              },
            })
          }
          setError(!data?.result?.isVaild)
          setErrorCode(data?.message);
          setValue('')
          setTimeout(() => {
            setError(false)
          }, 5000)
        }
        getBasketPromoses && getBasketPromoses(basketId)
        // const {
        //   result: {
        //     basket: { promotionsApplied },
        //   },
        // } = data
        // const mobileNumber = user?.mobile || user?.telephone || user?.phone
        // recordGA4Event(window, GA_EVENT.SELECT_PROMOTION, {
        //   ecommerce: {
        //     items: [
        //       items?.lineItems?.map((item: any, itemId: number) => ({
        //         item_name: item?.name,
        //         item_brand: item?.brand,
        //         item_category2: getCategories(item).category2,
        //         item_category: getCategories(item).category,
        //         item_variant: item?.colorName,
        //         color: item?.colorName,
        //         quantity: item?.qty,
        //         item_id: item?.sku,
        //         item_size: getLineItemSize(
        //           item?.stockCode
        //             ?.toUpperCase()
        //             ?.substring(item?.stockCode?.lastIndexOf('-') + 1),
        //           item?.variantProductsAttribute
        //         ),
        //         price: item?.price?.raw?.withTax,
        //         item_var_id: item?.stockCode,
        //         item_list_name: getCategories(item).category,
        //         index: itemId,
        //       })),
        //     ],
        //     promotion_name: promotionsApplied[0]?.name,
        //     promotion_promocode: promotionsApplied[0]?.promoCode,
        //     selected_coupon: 'single',
        //     phone: await getSHA256Hash(mobileNumber),
        //     current_page: getCurrentPage(),
        //     loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        //     discount_applied: data?.result?.basket?.discount?.raw?.withTax, // NEED TO UPDATE APPLIED DISCOUNT AMOUNT
        //   },
        // })
        if (typeof window !== 'undefined') {
          trackMoEngageEvent(
            MO_ENGAGE_EVENT.CART_COUPON,
            {
              URL: window?.location?.href,
              label: promoCode,
              discount_applied:
                data?.result?.basket?.discount?.raw?.withTax || 0,
            },
            window
          )
        }
        setIsAppLoading(false)
        return data?.result?.isVaild ?? false
      } catch (error) {
        setIsAppLoading(false)
      }
      return false
    }
  }

  const applyCouponInput = async (ev: any, promo: any) => {
    const applyPromoResult = await handleSubmitInput('apply', promo)

    if (applyPromoResult) {
      setAppliedBasketPromo(promo)
      handleCloseCoupon(false)
      // setCouponApplied(true)
      setTimeout(() => {
        setCouponApplied(true)
      }, 1000)
    }
  }

  const PromotionsCount =
    basketPromos?.applicablePromotions?.length +
    basketPromos?.availablePromotions?.length

  let storeDetails = {
    id: null,
    name: null,
    customInfo1: '',
  }
  if (isChannelStore) {
    let addressInfo: any = ''
    addressInfo = Cookies?.get('storeDetails')
    storeDetails = addressInfo && JSON.parse(addressInfo)
  }
  const isCouponsAllowed =
    isChannelStore && storeDetails?.customInfo1?.toLowerCase() === 'no coupons'
      ? false
      : true

  const isWelcomeCoupon = () => {
    return COUPON_CODE_WITHOUT_LOGIN.includes(
      promoCodeForErrorHandling.toLowerCase().replaceAll(' ', '')
    )
  }
  const renderErrorMessage = () => {
    if (error) {
      return <PromotionErrorMessage errorCode={errorCode} />
    }
    return null
  }

  return (
    <>
      <div className="px-4 grid content-center w-full sm:px-8">
        {basketPromos && (
          <>
            <div
              className={`flex items-start justify-between pb-3  ${
                !isChannelStore ? 'border-b border-gray-200' : ''
              } `}
            >
              <div
                className="flex items-start justify-start gap-4"
                style={{ maxWidth: '75%' }}
              >
                <div className="flex">
                  <span className="sprite-icon coupon-icon"></span>
                </div>
                <div className="flex flex-col col-span-7">
                  <h3 className="font-semibold text-sm dark:text-black">
                    Apply Coupon
                  </h3>
                  <p className="text-xs font-normal text-gray-500">
                    {/* Or Apply Referral Code (for first purchase) */}
                    Apply Referral Code for 1st purchase
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-end">
                {isCouponsAllowed && PromotionsCount > 0 ? (
                  <h3
                    className="text-sm font-semibold text-orange-500 cursor-pointer mob-font-small-screen"
                    onClick={() => {
                      Router?.push(
                        { pathname: `${asPath}#couponopen` },
                        `${asPath}#couponopen`,
                        { shallow: true, scroll: false }
                      )
                    }}
                  >
                    View All {isChannelStore || !showPromoList ? '' : '→'}
                  </h3>
                ) : (
                  <>
                    {(isMobile || isIPadorTablet) && (
                      <div className="flex justify-end col-span-4">
                        {isCouponsAllowed && (
                          <h3
                            className="text-sm font-semibold text-orange-500 cursor-pointer mob-font-small-screen"
                            onClick={() => {
                              Router?.push(
                                { pathname: `${asPath}#couponopen` },
                                `${asPath}#couponopen`,
                                { shallow: true, scroll: false }
                              )
                            }}
                          >
                            View All{' '}
                            {isChannelStore || !showPromoList ? '' : '→'}
                          </h3>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {!isCouponsAllowed && (isMobile || isIPadorTablet) && (
          <div className="flex items-center w-full pt-2 mt-0 sm:px-8">
            <div className="w-full sm:pb-6">
              {/* <h3 className="text-lg font-bold text-black">Use Coupon</h3> */}
              <div className="flex flex-col mt-2 mb-4">
                <div className="flex items-center justify-center w-full border border-gray-100 bg-dark-grey hover:border-orange-400">
                  <input
                    name={'promotion-code'}
                    placeholder={APPLY_PROMOTION}
                    onChange={handleChange}
                    value={value}
                    className="w-full min-w-0 px-4 py-3 text-gray-900 placeholder-gray-500 bg-transparent appearance-none focus:outline-none"
                  />

                  <button
                    onClick={async () =>
                      !isAppLoading &&
                      (await applyCouponInput('applyInput', value))
                    }
                    type="button"
                    title={GENERAL_APPLY_TEXT}
                    disabled={isAppLoading}
                    className={`max-w-xs flex-1 ml-5 py-2 px-4 flex items-center bg-transparent border-transparent justify-center font-medium hover:text-orange-500 text-orange-500 hover:bg-transparent sm:w-full`}
                  >
                    {GENERAL_APPLY_TEXT}
                  </button>
                </div>
              </div>
              {renderErrorMessage()}
            </div>
          </div>
        )}
      </div>
      <div
        className={`px-4 flex flex-col justify-startsm:px-8 sm:my-0 ${
          !isCouponsAllowed ? 'mb-6 my-0 ' : 'mb-4 my-0 '
        }`}
      >
        {cartItems.promotionsApplied?.length
          ? cartItems.promotionsApplied.map((promo: any, key: number) => {
              return (
                <>
                  <div className="mt-3">
                    <div
                      className="flex items-center justify-between gap-4"
                      key={key}
                    >
                      <div className="flex">
                        <span className="mr-3">
                          <GreenCouponIcon />
                        </span>
                        <h5 className="font-medium uppercase text-primary dark:text-black text-14 xs-text-14">
                          {' '}
                          {promo.promoCode}
                        </h5>
                      </div>
                      <div className="flex justify-end">
                        <h5 className="font-medium uppercase text-14 xs-text-14 text-emerald-600">
                          {promo?.discountAmt?.raw?.withTax > 0 ? (
                            <span>
                              -{promo?.discountAmt?.formatted?.withTax}
                            </span>
                          ) : (
                            <span>Free Gift Added</span>
                          )}
                          {!promo?.autoApply && (
                            <a href="javascript: void(0);">
                              <span
                                className="relative ml-2 sprite-icon cross-icon top-0.5"
                                onClick={() =>
                                  handleSubmit('remove', promo.promoCode)
                                }
                              ></span>
                            </a>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          : null}
      </div>

      {/* More Offer MSG */}
      {!hideBlueStrips && !isChannelStore && (
        <div className="px-4 sm:px-8">
          <MoreOfferMsg basketPromos={basketPromos} />
        </div>
      )}
      {/* More Offer MSG */}
      {showPromoList && (
        <PromoList
          basketPromos={basketPromos}
          onPromoApply={applyCouponInput}
          appliedList={cartItems?.promotionsApplied}
          previousPath={previousPath}
          deviceInfo={deviceInfo}
        />
      )}
      {SHOW_APPLY_COUPON_SECTION && !(isMobile || isIPadorTablet) && (
        <div className="flex items-center w-full pt-2 mt-0 sm:px-8">
          <div className="w-full sm:pb-6">
            <h3 className="text-lg font-bold text-black">Use Coupon</h3>
            <div className="flex flex-col mt-4">
              <div className="flex items-center justify-center w-full border border-gray-100 bg-dark-grey hover:border-orange-400">
                <input
                  name={'promotion-code'}
                  placeholder={APPLY_PROMOTION}
                  onChange={handleChange}
                  value={value}
                  className="w-full min-w-0 px-4 py-3 text-gray-900 placeholder-gray-500 bg-transparent appearance-none focus:outline-none"
                />

                <button
                  onClick={async () =>
                    !isAppLoading &&
                    (await applyCouponInput('applyInput', value))
                  }
                  type="button"
                  title={GENERAL_APPLY_TEXT}
                  disabled={isAppLoading}
                  className={`max-w-xs flex-1 ml-5 py-2 px-4 flex items-center bg-transparent border-transparent justify-center font-medium hover:text-orange-500 text-orange-500 hover:bg-transparent sm:w-full`}
                >
                  {GENERAL_APPLY_TEXT}
                </button>
              </div>
            </div>
            {renderErrorMessage()}
          </div>
        </div>
      )}

      {/* SHOW COUPONS PANEL */}
      <CouponsPanelPopup
        isShowCoupon={isShowCoupon}
        handleCloseCoupon={handleCloseCoupon}
        deviceInfo={deviceInfo}
        handleChange={handleChange}
        items={items}
        handleSubmit={handleSubmit}
        setError={setError}
        setAppliedBasketPromo={setAppliedBasketPromo}
        setCouponApplied={setCouponApplied}
        paymentOffers={paymentOffers}
        basketPromos={basketPromos}
        value={value}
        isAppLoading={isAppLoading}
        applyCouponInput={applyCouponInput}
        error={error}
        previousPath={previousPath}
        renderErrorMessage={renderErrorMessage}
      />

      <AppliedCouponPopup
        isCouponApplied={isCouponApplied}
        setCouponApplied={setCouponApplied}
        appliedBasketPromo={appliedBasketPromo}
      />

      {/* Referral input Show Panel */}
      <ReferralInputPopup
        isReferralShow={isReferralShow}
        handleReferralShow={handleReferralShow}
        deviceInfo={deviceInfo}
        referralErrorMsg={referralErrorMsg}
        isAppLoading={isAppLoading}
        setAppliedBasketPromo={setAppliedBasketPromo}
        handleSubmitInput={handleSubmitInput}
        handleCloseCoupon={handleCloseCoupon}
        setCouponApplied={setCouponApplied}
        setReferralErrorMsg={setReferralErrorMsg}
      />
    </>
  )
}

export default Promotions
