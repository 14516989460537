import { Cross } from '@components/icons'
import { useUI } from '@components/ui'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

interface IProps {
  isCouponApplied: boolean
  setCouponApplied: (val: boolean) => void
  appliedBasketPromo: any
}

const AppliedCouponPopup = (props: IProps) => {
  const { isCouponApplied, setCouponApplied, appliedBasketPromo } = props

  const { cartItems } = useUI()

  return (
    <Transition.Root show={isCouponApplied} as={Fragment}>
      <Dialog as="div" className="relative z-99" onClose={setCouponApplied}>
        <div className="fixed inset-0 left-0 bg-orange-600/0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-0 pointer-events-none sm:pl-0">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-200 sm:duration-200"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-200 sm:duration-200"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Dialog.Panel className="w-screen pointer-events-auto">
                  <div className="relative flex flex-col h-full shadow-xl bg-black/[.5] z-99">
                    <div className="w-full h-auto max-w-md mx-auto bg-white center-fix-panel">
                      <div className="sticky top-0 z-10 px-4 py-4 border-b border-gray-200 sm:px-6 left-1">
                        <div className="flex justify-between">
                          <h3 className="text-xl font-bold text-black dark:text-black">
                            Coupon Applied
                          </h3>
                          <button
                            type="button"
                            className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                            onClick={() => setCouponApplied(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <Cross
                              className="relative top-0 w-6 h-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col px-6">
                        <div className="mt-3 border border-gray-200">
                          <div className="grid grid-cols-12">
                            <div className="relative col-span-1 text-white coupon-code-panel coupon-cross-success">
                              <h3 className="text-sm font-semibold text-white uppercase coupon-text-rotate">
                                Applied
                              </h3>
                            </div>
                            {/* {JSON.stringify(cartItems?.promotionsApplied)} */}
                            {cartItems?.promotionsApplied?.filter(
                              (x: any) => x?.promoType == 9
                            )?.length > 0 ||
                            cartItems?.promotionsApplied?.filter(
                              (x: any) => x?.promoType == 2
                            )?.length > 0 ||
                            cartItems?.promotionsApplied?.filter(
                              (x: any) => x?.promoType == 4
                            )?.length > 0 ||
                            cartItems?.promotionsApplied?.length == 0 ? (
                              <>
                                <div className="col-span-11 p-4 coupon-code-data">
                                  <div className="flex justify-between">
                                    <h3 className="text-sm font-semibold text-black uppercase">
                                      {appliedBasketPromo?.code ||
                                        appliedBasketPromo}
                                    </h3>
                                  </div>
                                  {/* <div className="flex flex-col mt-2">
                                    <p className="text-xs font-normal text-emerald-500">
                                      You've got free gift in your basket.
                                    </p>
                                  </div> */}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-span-11 p-4 coupon-code-data">
                                  <div className="flex justify-between">
                                    <h3 className="text-sm font-semibold text-black uppercase">
                                      {appliedBasketPromo?.code ||
                                        appliedBasketPromo}
                                    </h3>
                                  </div>
                                  {/* <div className="flex flex-col mt-2">
                                    <p className="text-xs font-normal text-emerald-500">
                                      You've got{' '}
                                      {cartItems?.discount?.formatted?.withTax}{' '}
                                      discount
                                    </p>
                                  </div> */}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col px-6 py-4">
                        <button
                          type="button"
                          className="w-full text-white bg-black border border-black btn-basic-property hover:text-white hover:bg-gray-800"
                          onClick={() => setCouponApplied(false)}
                        >
                          Awesome!
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default AppliedCouponPopup
