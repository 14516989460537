const GreyGuaranteeMsgIcon = ({ ...props }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.9451 13.9199L3.41472 12.3223C1.2766 10.9726 0 8.6569 0 6.12807V1.95891C0 1.72142 0.16126 1.51427 0.391483 1.4562L6.09521 0.0157501C6.17843 -0.00525002 6.2658 -0.00525002 6.34903 0.0157501L12.0528 1.4562C12.283 1.51427 12.4442 1.72142 12.4442 1.95891V6.12807C12.4442 8.65664 11.1679 10.9724 9.02952 12.3223L6.49888 13.9199C6.41436 13.9733 6.31817 14 6.22199 14C6.1258 14 6.02962 13.9733 5.9451 13.9199ZM6.22225 1.05331L1.03704 2.36257H1.0373V6.12781C1.0373 8.2986 2.13319 10.2863 3.96876 11.4452L6.22225 12.8681L8.476 11.4452C10.3116 10.2863 11.4075 8.2986 11.4075 6.12781V2.36257L6.22225 1.05331ZM5.40205 8.33467C5.26931 8.33467 5.13657 8.28411 5.03546 8.18274L3.39486 6.54214C3.19237 6.33966 3.19237 6.01144 3.39486 5.80895C3.59708 5.60647 3.92582 5.60647 4.12805 5.80895L5.40205 7.08296L8.31666 4.16835C8.51888 3.96587 8.84762 3.96587 9.04984 4.16835C9.25233 4.37084 9.25233 4.69906 9.04984 4.90154L5.76864 8.18274C5.66753 8.28411 5.53479 8.33467 5.40205 8.33467Z"
        fill="black"
        fill-opacity="0.64"
      />
    </svg>
  )
}

export default GreyGuaranteeMsgIcon
