const PromoGreenStrip = ({ ...props }) => {
  return (
    <svg
      width="8"
      height="96"
      viewBox="0 0 8 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2084_20)">
        <rect
          width="136"
          height="8.00001"
          transform="translate(0 136) rotate(-90)"
          fill="#00890E"
        />
        <path
          d="M3.57771 133.789C2.10361 133.052 2.10361 130.948 3.57771 130.211L8 128L8 136L3.57771 133.789Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 125.789C2.10361 125.052 2.10361 122.948 3.57771 122.211L8 120L8 128L3.57771 125.789Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 117.789C2.10361 117.052 2.10361 114.948 3.57771 114.211L8 112L8 120L3.57771 117.789Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 109.789C2.10361 109.052 2.10361 106.948 3.57771 106.211L8 104L8 112L3.57771 109.789Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 101.789C2.10361 101.052 2.10361 98.9482 3.57771 98.2111L8 96L8 104L3.57771 101.789Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 93.7889C2.10361 93.0518 2.10361 90.9482 3.57771 90.2111L8 88L8 96L3.57771 93.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 85.7889C2.10361 85.0518 2.10361 82.9482 3.57771 82.2111L8 80L8 88L3.57771 85.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 77.7889C2.10361 77.0518 2.10361 74.9482 3.57771 74.2111L8 72L8 80L3.57771 77.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 69.7889C2.10361 69.0518 2.10361 66.9482 3.57771 66.2111L8 64L8 72L3.57771 69.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 61.7889C2.10361 61.0518 2.10361 58.9482 3.57771 58.2111L8 56L8 64L3.57771 61.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 53.7889C2.10361 53.0518 2.10361 50.9482 3.57771 50.2111L8 48L8 56L3.57771 53.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 45.7889C2.10361 45.0518 2.10361 42.9482 3.57771 42.2111L8 40L8 48L3.57771 45.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 37.7889C2.10361 37.0518 2.10361 34.9482 3.57771 34.2111L8 32L8 40L3.57771 37.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 29.7889C2.10361 29.0518 2.10361 26.9482 3.57771 26.2111L8 24L8 32L3.57771 29.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 21.7889C2.10361 21.0518 2.10361 18.9482 3.57771 18.2111L8 16L8 24L3.57771 21.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 13.7889C2.10361 13.0518 2.10361 10.9482 3.57771 10.2111L8 8L8 16L3.57771 13.7889Z"
          fill="#EBF6EC"
        />
        <path
          d="M3.57771 5.78885C2.10361 5.05181 2.10361 2.94819 3.57771 2.21115L8 -3.11264e-07L8 8L3.57771 5.78885Z"
          fill="#EBF6EC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2084_20">
          <rect
            width="136"
            height="8.00001"
            fill="white"
            transform="translate(0 136) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PromoGreenStrip
